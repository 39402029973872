import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { handleGenericErrorByStatusCode } from "../error/ErrorSlice";
import {
  secureAccountLaterPageLoaded,
  skipMobileEnrollment,
  skipMobileEnrollmentSagaFailed,
} from "../skip-mobile-enrollment/SkipMobileEnrollmentSlice";
import { saveMobileNumberSagaFailed } from "../verify-mobile/MobileNumberSlice";

export interface LoaderState {
  showLoader: boolean;
}
const initialState: LoaderState = {
  showLoader: true,
};

export const loaderSlice = createSlice({
  name: "loaderSlice",
  initialState,
  reducers: {
    setShowLoader: (state, action: PayloadAction<boolean>) => {
      state.showLoader = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleGenericErrorByStatusCode, (state) => {
        state.showLoader = false;
      })
      .addCase(secureAccountLaterPageLoaded, (state) => {
        state.showLoader = false;
      })
      .addCase(skipMobileEnrollment, (state) => {
        state.showLoader = true;
      })
      .addCase(skipMobileEnrollmentSagaFailed, (state) => {
        state.showLoader = false;
      })
      .addCase(saveMobileNumberSagaFailed, (state) => {
        state.showLoader = false;
      });
  },
});

export const { setShowLoader } = loaderSlice.actions;

export default loaderSlice.reducer;
