import { AppConfigState } from "../app-config/AppConfigSlice";
import { RootState } from "../../store/Store";
import { RouteState } from "../route/RouteSlice";
import { OtcVerificationState } from "../mfa/OtcVerificationSlice";
import { redirectToUrl } from "../../common/utils/navigation";
import { select, put } from "redux-saga/effects";
import { logError } from "../../common/application-insights/AppInsights";
import {
  ComponentNames,
  FunctionNames,
} from "../../common/application-insights/Constants";
import { ContinueState } from "./ContinueSlice";
import { setShowLoader } from "../loader/LoaderSlice";

export default function* continueSaga() {
  try {
    const { auth0Config }: AppConfigState = yield select(
      (state: RootState) => state.appConfig
    );

    const { auth0State, adobeMc }: RouteState = yield select(
      (state: RootState) => state.route
    );

    const { signedToken }: OtcVerificationState = yield select(
      (state: RootState) => state.otcVerification
    );

    const { continueToken }: ContinueState = yield select(
      (state: RootState) => state.continue
    );

    const params = new URLSearchParams({
      state: auth0State,
      token: signedToken || continueToken,
    });

    if (adobeMc) {
      params.append("adobe_mc", adobeMc);
    }

    const url = `${auth0Config.auth0Domain}/continue?${params.toString()}`;

    yield put(setShowLoader(true));

    redirectToUrl(url);
  } catch (e) {
    logError(
      new Error(e.code),
      ComponentNames.App.toString(),
      FunctionNames.continueSaga.toString()
    );
  }
}
