import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WithNav } from "../../utils/withNav";

export interface MobileNumberState {
  mobile: string;
  mobileNumberErrorMessage: string;
}

export interface MobileVerificationRequest {
  mobile: string;
  dontAskAgain: boolean;
}

const initialState: MobileNumberState = {
  mobile: "",
  mobileNumberErrorMessage: "",
};
export interface MobileInput {
  mobileNumber: string;
}

export const mobileNumberSlice = createSlice({
  name: "mobileVerification",
  initialState,
  reducers: {
    setMobileNumber: (state, action: PayloadAction<string>) => {
      state.mobile = action.payload;
    },
    saveMobileNumber: (state, action: PayloadAction<WithNav<MobileInput>>) => {
      state.mobile = action.payload.mobileNumber;
    },
    saveMobileNumberSagaFailed: () => {},
    mobileVerificationFailed: (state, action: PayloadAction<string>) => {
      state.mobileNumberErrorMessage = action.payload;
    },
    clearMobileVerificationErrorMessage: (state) => {
      state.mobileNumberErrorMessage = "";
    },
  },
});

export const {
  setMobileNumber,
  saveMobileNumber,
  saveMobileNumberSagaFailed,
  mobileVerificationFailed,
  clearMobileVerificationErrorMessage,
} = mobileNumberSlice.actions;

export default mobileNumberSlice.reducer;
