import React from "react";
import styled from "styled-components";
import {
  ErrorCrossCircleNew,
  Heading1New,
  Link,
  ThemedButtonNew,
} from "ccp-common-ui-components";
import { useSelector } from "react-redux";
import { RootState } from "../../store/Store";

const MainDiv = styled.div`
  margin: 40px 10px;
  text-align: center;
`;

const StyledHeading1New = styled(Heading1New)`
  margin-bottom: 8px;
`;

const Message = styled.p`
  margin-top: 0px;
  margin-bottom: 24px;
`;

const ContactColesLink = styled(Link)`
  font-size: 18px;
`;

export default function InvalidServerReponseErrorPage() {
  const originLoginUrl = useSelector(
    (state: RootState) => state.appConfig.originLoginUrl
  );

  return (
    <MainDiv>
      <ErrorCrossCircleNew role="presentation" />
      <StyledHeading1New>Something went wrong</StyledHeading1New>
      <Message>
        We can't log you in. Please try again, and if that doesn't work{" "}
        <ContactColesLink
          primary={true}
          href="https://www.coles.com.au/help/contact#call"
          target="_blank"
        >
          {" "}
          contact Customer Care
        </ContactColesLink>
      </Message>
      <ThemedButtonNew
        isFullWidth={true}
        data-testid="back-button"
        onClick={() => {
          window.location.replace(originLoginUrl);
        }}
      >
        Retry log in
      </ThemedButtonNew>
    </MainDiv>
  );
}
