import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useLocation, useRouteMatch } from "react-router-dom";
import { useQuery } from "../../common/hooks/useQuery";
import { RootState } from "../../store/Store";
import { dataLayerFlybuysVerifyCallbackPage } from "../../tracking/tracking";
import { verifyFlybuysLinkingAction } from "./FlybuysAction";
import FlybuysLinkedPage from "./FlybuysLinkedPage";
import FlybuysLinkingFailedPage from "./FlybuysLinkingFailedPage";
import { useWithNav } from "../../utils/withNav";
import { VerifyFlybuysLinkingRequest } from "./FlybuysSlice";

const VerifyFlybuysAccountPage = () => {
  const { path } = useRouteMatch();
  const { pathname: currentLocationPath } = useLocation();
  const queryParams = useQuery();
  const dispatch = useDispatch();

  const verifyFlybuysWithNav = useWithNav<VerifyFlybuysLinkingRequest>();

  useEffect(() => {
    if (path !== currentLocationPath) {
      return;
    }

    const code = queryParams.get("code") || "";
    const state = queryParams.get("state") || "";
    const userCancelled = queryParams.get("linkingStatus") === "userCancelled";

    dispatch(
      verifyFlybuysLinkingAction(
        verifyFlybuysWithNav({ code, state, userCancelled })
      )
    );
  }, []);

  const clientName = useSelector(
    (state: RootState) => state.channel.clientName
  );

  useEffect(() => {
    if (clientName) {
      dataLayerFlybuysVerifyCallbackPage(clientName);
    }
  }, [clientName]);

  return (
    <>
      <Route exact path={`${path}/success`}>
        <FlybuysLinkedPage />
      </Route>
      <Route exact path={`${path}/error`}>
        <FlybuysLinkingFailedPage />
      </Route>
    </>
  );
};

export default VerifyFlybuysAccountPage;
