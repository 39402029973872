import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select } from "redux-saga/effects";
import { auth0Logout } from "../../common/utils/navigation";
import { RootState } from "../../store/Store";
import { AppConfigState } from "../app-config/AppConfigSlice";
import { continueRule, setContinueToken } from "../continue/ContinueSlice";
import { setAuth0State } from "../route/RouteSlice";
import {
  VerifyFlybuysLinkingRequest,
  VerifyFlybuysLinkingResponse,
} from "./FlybuysSlice";
import {
  FlybuysVerifyError,
  FlybuysVerifyErrorResponseData,
  getFlybuysVerifyErrorResponse,
} from "./GetFlybuysVerifyErrorResponse";
import { WithNav } from "../../utils/withNav";

export const appConfigSelector = (state: RootState) => state.appConfig;

export default function* verifyFlybuysAccountSaga(
  action: PayloadAction<WithNav<VerifyFlybuysLinkingRequest>>
) {
  try {
    const { auth0Config, bffConfig }: AppConfigState = yield select(
      appConfigSelector
    );

    const userCancelled = action.payload.userCancelled;

    const requestBody = {
      code: action.payload.code,
      state: action.payload.state,
      userCancelled: userCancelled,
    };

    const response: Response = yield call(
      fetch,
      bffConfig.verifyFlybuysLinkingEndpoint,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(requestBody),
        mode: "cors",
        credentials: "include",
      }
    );

    const verifyResponse: VerifyFlybuysLinkingResponse = yield call([
      response,
      "json",
    ]);

    let state = verifyResponse.state;
    let token = verifyResponse.token;

    if (!response.ok) {
      const errorResponseBody = verifyResponse as FlybuysVerifyErrorResponseData;
      const errorObject = new FlybuysVerifyError(
        errorResponseBody.state,
        errorResponseBody.token
      );

      const verifyResponseData = getFlybuysVerifyErrorResponse(errorObject);

      state = verifyResponseData.state;
      token = verifyResponseData.token;
    }

    yield put(setAuth0State(state));
    yield put(setContinueToken(token));

    const callbackRoute = "/callback/flybuys";

    if (userCancelled) {
      if (!state || !token) {
        yield call(auth0Logout, auth0Config.auth0LogoutEndPoint);
        return;
      }

      yield put(continueRule());
      return;
    }

    const route = response.ok
      ? `${callbackRoute}/success`
      : `${callbackRoute}/error`;

    action.payload.push(route);
  } catch {
    action.payload.push("/callback/flybuys/error");
  }
}
