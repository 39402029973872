import { call, select, put } from "redux-saga/effects";
import {
  mfaVerificationFailed,
  mfaVerificationFailedGeneric,
} from "../../common/validation/ErrorMessages";
import {
  sendOtcFailed,
  sentOtcSuccessfully,
  SendMfaCodeResponse,
  sendOtcMaxRetryReached,
  SendOtcState,
  SendMfaCodeErrorResponse,
  setChannel,
  sendOtc,
  smsGatewayIsUnavailable,
} from "./SendOtcSlice";
import { RootState } from "../../store/Store";
import { AppConfigState } from "../app-config/AppConfigSlice";
import { RouteState } from "../route/RouteSlice";
import { setShowLoader } from "../loader/LoaderSlice";
import { logError } from "../../common/application-insights/AppInsights";
import {
  ComponentNames,
  ErrorCodes,
  FunctionNames,
} from "../../common/application-insights/Constants";
import { setErrorCode } from "../application-error/ApplicationErrorSlice";
import { ApplicationErrorCode } from "../application-error/ApplicationErrorCodes";
import { PayloadAction } from "@reduxjs/toolkit";
import { WithNav } from "../../utils/withNav";
import { isRouteMfaMobileExempt } from "../../common/commonFunctions";

export default function* sendOtcSaga(action: PayloadAction<WithNav>) {
  try {
    const { channel }: SendOtcState = yield select(
      (state: RootState) => state.sendOtc
    );

    const { route, jwtToken }: RouteState = yield select(
      (state: RootState) => state.route
    );

    if (!jwtToken) return;

    const { bffConfig }: AppConfigState = yield select(
      (state: RootState) => state.appConfig
    );

    yield put(setShowLoader(true));

    let sendCodeEndPoint =
      channel === "email"
        ? bffConfig.mfaSendEmailCodeEndPoint
        : bffConfig.mfaSendCodeEndPoint;

    let navigateTo = "/secureLogin";

    if (route === "verifymobile" || route === "personalisationB2B") {
      navigateTo = "/verifyMobile";
    }

    const response: Response = yield call(fetch, sendCodeEndPoint, {
      mode: "cors",
      headers: { JwtToken: jwtToken, "Content-Type": "application/json" },
      method: "POST",
    });

    const responseBody: SendMfaCodeResponse = yield call([response, "json"]);

    if (response.ok) {
      const successResponse = responseBody;

      if (!successResponse.data.canFlags.canSendCode) {
        yield put(sendOtcMaxRetryReached());
      }

      if (
        successResponse.data.channel.toLowerCase() === "sms" ||
        successResponse.data.channel.toLowerCase() === "email"
      ) {
        yield put(sentOtcSuccessfully(successResponse));
        yield put(setChannel(successResponse.data.channel.toLowerCase()));
        action.payload.push(navigateTo);
      } else {
        yield put(sendOtcFailed(mfaVerificationFailed));
      }
      yield put(setShowLoader(false));
    } else {
      yield handleError(response, responseBody);
    }
  } catch (ex) {
    yield put(setShowLoader(false));
    logError(
      new Error(ex.code),
      ComponentNames.SendOtcSaga,
      FunctionNames.sendOtcSaga
    );
    yield put(sendOtcFailed(mfaVerificationFailedGeneric));
  }

  function* handleError(response: Response, responseBody: any) {
    yield put(setShowLoader(false));

    logError(
      new Error(ErrorCodes.RequestThrottled),
      ComponentNames.SendOtcSaga,
      FunctionNames.sendOtcSaga
    );

    const { channel, preVerification, smsGatewayUnavailable } = yield select(
      (state: RootState) => state.sendOtc
    );

    const { route } = yield select((state: RootState) => state.route);

    if (response.status === 500 || response.status === 503) {
      if (
        (preVerification && route === "verifymobile") ||
        (channel === "email" && smsGatewayUnavailable && route === "mfa") ||
        isRouteMfaMobileExempt(route)
      ) {
        yield put(setErrorCode(ApplicationErrorCode.VerifyMobileError));
        action.payload.push("/verifyMobileError");
      } else if (channel === "sms" && route === "mfa") {
        yield put(smsGatewayIsUnavailable());
        yield put(setChannel("email"));
        yield put(
          sendOtc({
            push: action.payload.push,
          })
        );
      }
    } else if (response.status === 400) {
      const errorResponse: SendMfaCodeErrorResponse = responseBody;

      if (
        !errorResponse.error.canSendCode ||
        !errorResponse.error.canVerifyCode
      ) {
        yield put(sendOtcMaxRetryReached());

        if (preVerification) {
          if (route !== "verifymobile") {
            if (!errorResponse.error.canVerifyCode) {
              yield put(
                setErrorCode(ApplicationErrorCode.SecureLoginVerifyCodeExceeded)
              );
            } else {
              yield put(
                setErrorCode(ApplicationErrorCode.SecureLoginSendOtcExceeded)
              );
            }
            action.payload.push("/secureLoginError");
          } else {
            if (!errorResponse.error.canVerifyCode) {
              yield put(
                setErrorCode(
                  ApplicationErrorCode.VerifyMobileVerifyCodeExceeded
                )
              );
            } else {
              yield put(
                setErrorCode(ApplicationErrorCode.VerifyMobileSendOtcExceeded)
              );
            }
            action.payload.push("/verifyMobileError");
          }
        }
      }
    } else {
      yield put(sendOtcFailed(mfaVerificationFailedGeneric));
    }
  }
}
