import { RootState } from "../../store/Store";
import { call, put, select } from "redux-saga/effects";
import {
  MobileInput,
  MobileNumberState,
  saveMobileNumberSagaFailed,
} from "./MobileNumberSlice";
import { AppConfigState } from "../app-config/AppConfigSlice";
import { RouteState } from "../route/RouteSlice";
import { sendOtc } from "../mfa/SendOtcSlice";
import { setShowLoader } from "../loader/LoaderSlice";
import { logError } from "../../common/application-insights/AppInsights";
import {
  ComponentNames,
  FunctionNames,
} from "../../common/application-insights/Constants";
import { handleGenericErrorByStatusCode } from "../error/ErrorSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { WithNav } from "../../utils/withNav";

export default function* saveMobileNumberSaga(
  action: PayloadAction<WithNav<MobileInput>>
) {
  try {
    yield put(setShowLoader(true));

    const { mobile }: MobileNumberState = yield select(
      (state: RootState) => state.mobileNumber
    );

    const { bffConfig }: AppConfigState = yield select(
      (state: RootState) => state.appConfig
    );

    const { jwtToken }: RouteState = yield select(
      (state: RootState) => state.route
    );

    const requestBody = { mobile: mobile };
    const response: Response = yield call(fetch, bffConfig.saveMobileEndPoint, {
      headers: { JwtToken: jwtToken, "Content-Type": "application/json" },
      method: "PATCH",
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      yield put(
        sendOtc({
          push: action.payload.push,
        })
      );
    } else {
      yield put(
        handleGenericErrorByStatusCode({
          push: action.payload.push,
          statusCode: response.status,
        })
      );
    }
  } catch (e) {
    logError(
      new Error(e.code),
      ComponentNames.SaveMobileNumberSaga,
      FunctionNames.saveMobileNumberSaga
    );
    yield put(saveMobileNumberSagaFailed());
  }
}
