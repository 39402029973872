import React, { useEffect } from "react";
import styled from "styled-components";
import {
  Heading1New,
  ThemedButtonNew,
  ErrorCrossCircleNew,
} from "ccp-common-ui-components";
import { useDispatch, useSelector } from "react-redux";
import { verifyLater } from "../verify-later/VerifyLaterSlice";
import { RootState } from "../../store/Store";
import { getErrorMessage } from "../application-error/ApplicationErrorUtils";
import { dataLayerVerifyMobileErrorPage } from "../../tracking/tracking";
import { useWithNav } from "../../utils/withNav";

const VerifyMobileErrorPage = function () {
  const dispatch = useDispatch();
  const withNav = useWithNav();

  const clientName = useSelector(
    (state: RootState) => state.channel.clientName
  );

  useEffect(() => {
    if (clientName) {
      dataLayerVerifyMobileErrorPage(clientName);
    }
  }, [clientName]);

  const { errorCode } = useSelector(
    (state: RootState) => state.applicationError
  );

  const errorMessage = getErrorMessage(errorCode);

  return (
    <MainDiv>
      <ErrorCrossCircleNew role="presentation" />
      <Heading1New>Cannot verify</Heading1New>
      <Message>{errorMessage}</Message>
      <StyledButton
        data-testid="verify-later-button"
        onClick={(e) => {
          e.preventDefault();
          dispatch(verifyLater(withNav()));
        }}
      >
        Verify later
      </StyledButton>
    </MainDiv>
  );
};

export default VerifyMobileErrorPage;

const MainDiv = styled.div`
  margin: 40px 10px;
  text-align: center;
`;

const Message = styled.p`
  margin-bottom: 40px;
`;

const StyledButton = styled(ThemedButtonNew)`
  width: 100%;
`;
