import { ApplicationErrorCode } from "./ApplicationErrorCodes";
import { sendOrVerifyOtcExceededErrorText } from "../../common/validation/ErrorMessages";

// TODO: come back here when we remove the uiLayoutFeatureToggle and merge the 3 error components into 1:
// SecureLoginErrorPage, VerifyMobileErrorPage and GenericErrorPage
// in addition, consolidate error folder (which also handles external errors) with application errors folder
export function getErrorMessage(errorCode: ApplicationErrorCode) {
  switch (errorCode) {
    case ApplicationErrorCode.LoginFailedError:
      return "We're sorry something went wrong. Please try logging in again or go back to Coles website.";
    case ApplicationErrorCode.ServiceUnavailableError:
      return "We're sorry, we are currently experiencing a network issue. Please try logging in again or go back to Coles website.";
    case ApplicationErrorCode.SecureLoginVerifyCodeExceeded:
      return sendOrVerifyOtcExceededErrorText;
    case ApplicationErrorCode.SecureLoginSendOtcExceeded:
      return sendOrVerifyOtcExceededErrorText;
    case ApplicationErrorCode.VerifyMobileError:
      return "Something went wrong.";
    case ApplicationErrorCode.VerifyMobileVerifyCodeExceeded:
      return sendOrVerifyOtcExceededErrorText;
    case ApplicationErrorCode.VerifyMobileSendOtcExceeded:
      return sendOrVerifyOtcExceededErrorText;
    default:
    case ApplicationErrorCode.Unknown:
      throw new Error("Error code undefined");
  }
}

export function getErrorTitle(errorCode: ApplicationErrorCode) {
  switch (errorCode) {
    case ApplicationErrorCode.LoginFailedError:
      return "Login failed";
    case ApplicationErrorCode.ServiceUnavailableError:
    case ApplicationErrorCode.SecureLoginVerifyCodeExceeded:
    case ApplicationErrorCode.SecureLoginSendOtcExceeded:
    case ApplicationErrorCode.VerifyMobileError:
    case ApplicationErrorCode.VerifyMobileVerifyCodeExceeded:
    case ApplicationErrorCode.VerifyMobileSendOtcExceeded:
    default:
    case ApplicationErrorCode.Unknown:
      throw new Error("Error title undefined");
  }
}

export function getErrorContinueText(errorCode: ApplicationErrorCode) {
  switch (errorCode) {
    case ApplicationErrorCode.SecureLoginVerifyCodeExceeded:
    case ApplicationErrorCode.SecureLoginSendOtcExceeded:
      return "Return to login";
    case ApplicationErrorCode.LoginFailedError:
    case ApplicationErrorCode.ServiceUnavailableError:
    case ApplicationErrorCode.VerifyMobileError:
    case ApplicationErrorCode.VerifyMobileVerifyCodeExceeded:
    case ApplicationErrorCode.VerifyMobileSendOtcExceeded:
    default:
    case ApplicationErrorCode.Unknown:
      throw new Error("Error title undefined");
  }
}
