import React from "react";
import styled from "styled-components";
import { Heading1New, Link } from "ccp-common-ui-components";
import spilledMilk from "../../assets/icons/spilled-milk.svg";
import colesLogo from "../../assets/icons/coles-logo.svg";
import PrivacyPolicyLink from "../user-acceptances/PrivacyPolicyLink";

const spilledMilkSize = 200;
const colesLogoSize = 100;

const ErrorPageContent = styled.div`
  display: flex;
  background-color: #efeff4;
  height: 100%;
  flex-direction: column;
  text-align: center;
`;

const SpilledMilk = styled.img`
  width: ${spilledMilkSize}px;
  height: ${spilledMilkSize}px;
  margin-right: auto;
  margin-left: auto;
`;

const ColesLogo = styled.img`
  margin-right: auto;
  margin-left: auto;
  width: ${colesLogoSize}px;
  height: ${colesLogoSize}px;
  padding-top: 24px;
  padding-bottom: 24px;
`;

const Message = styled.p`
  font-weight: normal;
`;

const ContactColesLink = styled(Link)`
  font-size: 18px;
`;

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  font-size: 14px;
  width: 100%;
  flex-grow: 0;
  height: 48px;
  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    border-top: 1px solid #e5e5e5;
    justify-content: flex-start;
  }
`;

const BorderLine = styled.div`
  margin-top: auto;
  margin-right: auto;
  margin-left: auto;
  border-top: 1px solid rgb(232, 232, 232);
  width: ${(props) => props.theme.mobileBreakpoint};
  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    width: 80%;
  }
`;

export default function InvalidConfigurationErrorPage() {
  return (
    <ErrorPageContent>
      <ColesLogo
        src={colesLogo}
        alt=""
        width={colesLogoSize}
        height={colesLogoSize}
      />
      <SpilledMilk
        src={spilledMilk}
        alt=""
        width={spilledMilkSize}
        height={spilledMilkSize}
      />
      <Heading1New>Oops! Something went wrong</Heading1New>
      <Message>
        Please try again later. For further assistance{" "}
        <ContactColesLink
          primary={true}
          href="https://www.coles.com.au/help/contact#call"
          target="_blank"
        >
          {" "}
          contact Coles customer support
        </ContactColesLink>
      </Message>
      <BorderLine />
      <FooterContent>
        <PrivacyPolicyLink />
      </FooterContent>
    </ErrorPageContent>
  );
}
