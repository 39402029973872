import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface VerifyLaterResponse {
  data: VerifyLaterData;
}

export interface VerifyLaterData {
  token: string;
}

export interface ContinueState {
  continueToken: string;
}

const initialState = {
  continueToken: "",
};

export const continueSlice = createSlice({
  name: "auth0-continue",
  initialState,
  reducers: {
    setContinueToken: (state, action: PayloadAction<string>) => {
      state.continueToken = action.payload;
    },
    continueRule: (state) => {},
  },
});

export const { setContinueToken, continueRule } = continueSlice.actions;

export default continueSlice.reducer;
