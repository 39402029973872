import React from "react";
import styled from "styled-components";
import ThemedCheckBox from "../../common/inputs/ThemedCheckBox";

export interface DontAskAgainCheckBoxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const DontAskAgainCheckBoxDiv = styled.div`
  display: flex;
`;

export const DontAskAgainCheckBox = React.forwardRef(
  (props: DontAskAgainCheckBoxProps, ref: React.Ref<HTMLInputElement>) => {
    return (
      <DontAskAgainCheckBoxDiv>
        <ThemedCheckBox {...props} ref={ref} name="dont-ask-again" />
        <label htmlFor="dont-ask-again">
          Don't ask me for two-step authentication again
        </label>
      </DontAskAgainCheckBoxDiv>
    );
  }
);
