import { PayloadAction } from "@reduxjs/toolkit";
import { call, select } from "redux-saga/effects";
import { logError } from "../../common/application-insights/AppInsights";
import {
  ComponentNames,
  FunctionNames,
} from "../../common/application-insights/Constants";
import { RootState } from "../../store/Store";
import { AppConfigState } from "../app-config/AppConfigSlice";
import { RouteState } from "../route/RouteSlice";
import { PreferenceRequest } from "./PreferencesTypes";

export default function* preferencesSaga(
  action: PayloadAction<PreferenceRequest>
) {
  try {
    const { bffConfig }: AppConfigState = yield select(
      (state: RootState) => state.appConfig
    );

    const { jwtToken }: RouteState = yield select(
      (state: RootState) => state.route
    );

    const preferenceName = action.payload.name;
    const preferenceValue = action.payload.value;

    const requestBody = {
      preferences: [
        {
          name: preferenceName,
          value: preferenceValue,
        },
      ],
    };

    yield call(fetch, bffConfig.preferencesUpdateEndPoint, {
      headers: { JwtToken: jwtToken, "Content-Type": "application/json" },
      method: "PATCH",
      body: JSON.stringify(requestBody),
      mode: "cors",
    });
  } catch (ex) {
    logError(
      new Error(ex.code),
      ComponentNames.PreferencesSaga,
      FunctionNames.preferencesSaga
    );
  }
}
