import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WithNav } from "../../utils/withNav";
export interface RouteState {
  route: string;
  auth0State: string;
  jwtToken: string;
  mobile: string;
  adobeMc?: string;
  isB2BUser: boolean;
}

const initialState: RouteState = {
  route: "",
  auth0State: "",
  jwtToken: "",
  mobile: "",
  isB2BUser: false,
};

export interface RouteRequest {
  auth0State: string;
  token: string;
  adobeMc: string;
}

export interface RouteResponse {
  data: RouteResponseData;
}

export interface RouteResponseData {
  route: string;
  token: string;
  mobile: string;
  isB2BUser: boolean;
}

export const routeSlice = createSlice({
  name: "route",
  initialState,
  reducers: {
    getRoute: (state, action: PayloadAction<WithNav<RouteRequest>>) => {
      state.auth0State = action.payload.auth0State;
      state.jwtToken = action.payload.token;
      state.adobeMc = action.payload.adobeMc;
    },
    gotRouteSuccessfully: (state, action: PayloadAction<RouteResponse>) => {
      state.route = action.payload.data.route;
    },
    updateRoute: (state, action: PayloadAction<string>) => {
      state.route = action.payload;
    },
    setAuth0State: (state, action: PayloadAction<string>) => {
      state.auth0State = action.payload;
    },
  },
});

export const {
  getRoute,
  gotRouteSuccessfully,
  updateRoute,
  setAuth0State,
} = routeSlice.actions;

export default routeSlice.reducer;
