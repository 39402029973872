import React from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import styled from "styled-components";

import SecureLoginPage from "./features/mfa/SecureLoginPage";

import VerifyMobilePage from "./features/verify-mobile/VerifyMobilePage";

import SecureAccountLaterPage from "./features/skip-mobile-enrollment/SecureAccountLaterPage";

import SecureLoginErrorPage from "./features/error/SecureLoginErrorPage";
import MobileNumberPage from "./features/verify-mobile/MobileNumberPage";
import VerifyMobileErrorPage from "./features/error/VerifyMobileErrorPage";
import GenericErrorPage from "./features/error/GenericErrorPage";
import InvalidServerReponseErrorPage from "./features/error/InvalidServerReponseErrorPage";
import LinkFlybuysPage from "./features/flybuys/LinkFlybuysPage";
import RelinkFlybuysPage from "./features/flybuys/RelinkFlybuysPage";
import VerifyFlybuysAccountPage from "./features/flybuys/VerifyFlybuysAccountPage";
import FlybuysSkipPromptPage from "./features/flybuys/FlybuysSkipPromptPage";
import BusinessAttributesPage from "./features/business/BusinessAttributesPage";

const LoadingDiv = styled.div`
  min-height: 300px;
`;

export interface SwitcherProps {
  flybuysFeatureToggle: boolean;
}

function Switcher(props: SwitcherProps) {
  return (
    <Switch>
      <Route exact path="/">
        <LoadingDiv />
      </Route>
      <Route exact path="/serverError">
        <InvalidServerReponseErrorPage />
      </Route>
      <Route exact path="/secureLogin">
        <SecureLoginPage />
      </Route>
      <Route exact path="/mobileNumber">
        <MobileNumberPage />
      </Route>
      <Route exact path="/verifyMobile">
        <VerifyMobilePage />
      </Route>
      <Route exact path="/secureAccountLater">
        <SecureAccountLaterPage />
      </Route>
      <Route exact path="/secureLoginError">
        <SecureLoginErrorPage />
      </Route>
      <Route exact path="/verifyMobileError">
        <VerifyMobileErrorPage />
      </Route>
      <Route exact path="/businessattributes">
        <BusinessAttributesPage />
      </Route>
      <Route path="/genericError">
        <GenericErrorPage />
      </Route>
      {props.flybuysFeatureToggle && (
        <Route path="/linkFlybuys">
          <LinkFlybuysPage />
        </Route>
      )}
      {props.flybuysFeatureToggle && (
        <Route path="/relinkFlybuys">
          <RelinkFlybuysPage />
        </Route>
      )}
      {props.flybuysFeatureToggle && (
        <Route path="/callback/flybuys">
          <VerifyFlybuysAccountPage />
        </Route>
      )}
      {props.flybuysFeatureToggle && (
        <Route path="/skipFlybuysPrompt">
          <FlybuysSkipPromptPage />
        </Route>
      )}
    </Switch>
  );
}

export default Switcher;
