import { call, select, put } from "redux-saga/effects";
import {
  mfaVerificationFailed,
  mfaVerificationFailedGeneric,
} from "../../common/validation/ErrorMessages";

import { RootState } from "../../store/Store";
import {
  OtcVerificationState,
  verifiedOtcFailed,
  VerifyCodeSuccessResponse,
  verifiedOtcSuccessfully,
  VerifyCodeErrorResponse,
  SecureLoginInputs,
} from "./OtcVerificationSlice";
import { AppConfigState } from "../app-config/AppConfigSlice";
import { RouteState } from "../route/RouteSlice";
import { SendOtcState } from "./SendOtcSlice";
import { setShowLoader } from "../loader/LoaderSlice";
import { logError } from "../../common/application-insights/AppInsights";
import {
  ComponentNames,
  ErrorCodes,
  FunctionNames,
} from "../../common/application-insights/Constants";
import { linkFlybuysAction } from "../flybuys/FlybuysAction";
import { setErrorCode } from "../application-error/ApplicationErrorSlice";
import { ApplicationErrorCode } from "../application-error/ApplicationErrorCodes";
import { PayloadAction } from "@reduxjs/toolkit";
import { PushType, WithNav } from "../../utils/withNav";
import { isRouteMfaMobileExempt } from "../../common/commonFunctions";

export default function* otcVerificationSaga(
  action: PayloadAction<WithNav<SecureLoginInputs>>
) {
  try {
    const { channel }: SendOtcState = yield select(
      (state: RootState) => state.sendOtc
    );

    const { oneTimeCode }: OtcVerificationState = yield select(
      (state: RootState) => state.otcVerification
    );

    const { bffConfig }: AppConfigState = yield select(
      (state: RootState) => state.appConfig
    );

    const { route, jwtToken }: RouteState = yield select(
      (state: RootState) => state.route
    );

    yield put(setShowLoader(true));

    const bffUrl =
      route === "mfa" || isRouteMfaMobileExempt(route)
        ? bffConfig.secureLoginEndPoint
        : bffConfig.verifyMobileEndPoint;

    const requestBody = { channel: channel, verificationCode: oneTimeCode };
    const response: Response = yield call(fetch, bffUrl, {
      headers: { JwtToken: jwtToken, "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify(requestBody),
      mode: "cors",
    });

    const errorRoute =
      route === "mfa" || isRouteMfaMobileExempt(route)
        ? "/secureLoginError"
        : "/verifyMobileError";

    if (route === "mfa" || isRouteMfaMobileExempt(route)) {
      yield put(
        setErrorCode(ApplicationErrorCode.SecureLoginVerifyCodeExceeded)
      );
    } else {
      yield put(
        setErrorCode(ApplicationErrorCode.VerifyMobileVerifyCodeExceeded)
      );
    }

    const responseBody = yield call([response, "json"]);

    if (response.ok) {
      const successResponse: VerifyCodeSuccessResponse = responseBody;

      if (
        !successResponse.data.verified &&
        !successResponse.data.canFlags.canVerifyCode
      ) {
        action.payload.push(errorRoute);
        yield put(setShowLoader(false));
        return;
      }

      if (successResponse.data.verified && successResponse.data.token) {
        yield put(verifiedOtcSuccessfully(successResponse.data.token));
        yield put(setShowLoader(false));
        yield put(
          linkFlybuysAction({
            push: action.payload.push,
          })
        );
      } else {
        yield put(verifiedOtcFailed(mfaVerificationFailed));
        yield put(setShowLoader(false));
      }
    } else {
      yield handleError(
        response,
        responseBody,
        errorRoute,
        action.payload.push
      );
    }
  } catch (ex) {
    logError(
      new Error(ex.code),
      ComponentNames.OtcVerificationSaga,
      FunctionNames.otcVerificationSaga
    );
    yield put(verifiedOtcFailed(mfaVerificationFailedGeneric));
    yield put(setShowLoader(false));
  }
}

function* handleError(
  response: Response,
  responseBody: any,
  errorRoute: string,
  push: PushType
) {
  yield put(setShowLoader(false));

  logError(
    new Error(ErrorCodes.RequestThrottled),
    ComponentNames.OtcVerificationSaga,
    FunctionNames.otcVerificationSaga
  );

  if (response.status === 503) {
    yield put(setErrorCode(ApplicationErrorCode.VerifyMobileError));
    push("/verifyMobileError");
  } else if (response.status === 400) {
    const errorResponse: VerifyCodeErrorResponse = responseBody;

    if (!errorResponse.error.canVerifyCode) {
      push(errorRoute);
    }
  } else {
    yield put(verifiedOtcFailed(mfaVerificationFailedGeneric));
  }
}
