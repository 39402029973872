import { Heading1New, ThemedButtonNew } from "ccp-common-ui-components";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { redirectToUrl } from "../../common/utils/navigation";
import { RootState } from "../../store/Store";
import {
  dataLayerFlybuysLinkPage,
  dataLayerFlybuysLinkPageNoThanks,
} from "../../tracking/tracking";
import {
  StyledIcon,
  StyledP,
  StyledDescription,
  Description,
  StyledSpan,
  StyledCenteredDiv,
  LinkContainer,
  NoThanksLink,
  SmallerDescription,
  StyledLink,
  BoldText,
  FlybuysPrivacyUrl,
} from "./LinkFlybuysPageStyles";
import { flybuysPreferencesAction } from "./FlybuysAction";

function LinkFlybuysPage() {
  const { linkFlybuysUrl, registerFlybuysUrl } = useSelector(
    (state: RootState) => state.linkFlybuys
  );

  const textPoints = [
    "Collect 1 point per $1 you spend in store or online.",
    "View items you've bought before in-store and online to make shopping easier.",
    "Redeem 2,000 points for $10 off when you use Flybuys dollars.",
  ];

  const dispatch = useDispatch();

  const clientName = useSelector(
    (state: RootState) => state.channel.clientName
  );

  useEffect(() => {
    if (clientName) {
      dataLayerFlybuysLinkPage(clientName);
    }
  }, [clientName]);

  return (
    <>
      <Heading1New>Link your Flybuys card</Heading1New>

      <StyledDescription>
        Whether it's a quick shop for essentials or stocking up the pantry,
        you'll collect points on every dollar you spend at Coles.
      </StyledDescription>

      <StyledP>
        {textPoints.map((textItem) => (
          <Description key={textItem}>
            <StyledIcon size={20} />
            <StyledSpan>{textItem}</StyledSpan>
          </Description>
        ))}
      </StyledP>

      <SmallerDescription>
        Once linked, you will also be able to collect Flybuys points on in-store
        purchases at Coles Supermarkets by scanning your Coles barcode.
      </SmallerDescription>
      <SmallerDescription>
        By scanning your Coles barcode, Flybuys will also share your email
        address and personal information with Coles in accordance with your
        email share settings and the{" "}
        <StyledLink href={FlybuysPrivacyUrl} target="_blank">
          Flybuys privacy policy
        </StyledLink>
        .
      </SmallerDescription>

      <StyledCenteredDiv>
        <ThemedButtonNew
          isFullWidth={true}
          onClick={() => {
            redirectToUrl(linkFlybuysUrl);
          }}
        >
          Link my flybuys
        </ThemedButtonNew>
      </StyledCenteredDiv>
      <LinkContainer>
        <NoThanksLink
          data-testid="no-thanks-link"
          onClick={(e) => {
            e.preventDefault();

            dispatch(flybuysPreferencesAction({ skipFlybuys: true }));

            dataLayerFlybuysLinkPageNoThanks();
          }}
        >
          Don’t ask me again
        </NoThanksLink>
      </LinkContainer>

      <SmallerDescription>
        <BoldText>Not a member? </BoldText>
        <StyledLink href={registerFlybuysUrl}>
          Register a Flybuys account
        </StyledLink>{" "}
        and start collecting points on your next shop.
      </SmallerDescription>
    </>
  );
}

export default LinkFlybuysPage;
