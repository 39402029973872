import { createSlice } from "@reduxjs/toolkit";
import configuration from "../../common/config/Configuration";

export interface FeatureToggleState {
  flybuysFeatureToggle: boolean;
  mfaMandatoryFeatureToggle: boolean;
}

export interface AppConfigState {
  bffConfig: BffConfig;
  appInsightsConfig: AppInsightsConfig;
  auth0Config: Auth0Config;
  featureToggleState: FeatureToggleState;
  originLoginUrl: string;
}

export interface BffConfig {
  baseUrl: string;
  routeEndPoint: string;
  verifyLaterEndPoint: string;
  mfaSendCodeEndPoint: string;
  mfaSendEmailCodeEndPoint: string;
  verifyMobileEndPoint: string;
  secureLoginEndPoint: string;
  saveMobileEndPoint: string;
  skipMobileEnrollmentEndPoint: string;
  setPersonalizationDetailsEndPoint: string;
  accountLinkagesEndPoint: string;
  getFlybuysLinkedEndPoint: string;
  verifyFlybuysLinkingEndpoint: string;
  migrationEndPoint: string;
  preferencesUpdateEndPoint: string;
  businessAttributesEndpoint: string;
}

export interface AppInsightsConfig {
  instrumentationKey: string;
}

export interface Auth0Config {
  auth0Domain: string;
  auth0LogoutEndPoint: string;
}

const initialState: AppConfigState = {
  bffConfig: {
    baseUrl: configuration.bffBaseUrl,
    routeEndPoint: `${configuration.bffBaseUrl}/route`,
    verifyLaterEndPoint: `${configuration.bffBaseUrl}/route/verifyLater`,
    mfaSendCodeEndPoint: `${configuration.bffBaseUrl}/mfa/sendcode`,
    mfaSendEmailCodeEndPoint: `${configuration.bffBaseUrl}/mfa/sendcode/email`,
    verifyMobileEndPoint: `${configuration.bffBaseUrl}/mfa/verifyMobile`,
    secureLoginEndPoint: `${configuration.bffBaseUrl}/mfa/secureLogin`,
    saveMobileEndPoint: `${configuration.bffBaseUrl}/profile/mobile`,
    skipMobileEnrollmentEndPoint: `${configuration.bffBaseUrl}/profile/skipMobileEnrollment`,
    setPersonalizationDetailsEndPoint: `${configuration.bffBaseUrl}/profile/personalisation`,
    accountLinkagesEndPoint: `${configuration.bffBaseUrl}/accountLinkages`,
    getFlybuysLinkedEndPoint: `${configuration.bffBaseUrl}/loyalty-accounts/flybuys/linking-url`,
    verifyFlybuysLinkingEndpoint: `${configuration.bffBaseUrl}/loyalty-accounts/flybuys/verify`,
    migrationEndPoint: `${configuration.bffBaseUrl}/migrations`,
    preferencesUpdateEndPoint: `${configuration.bffBaseUrl}/profile/preference`,
    businessAttributesEndpoint: `${configuration.bffBaseUrl}/business/attributes`,
  },
  appInsightsConfig: {
    instrumentationKey: configuration.appInsightsInstrumentationKey,
  },
  auth0Config: {
    auth0Domain: configuration.auth0Domain,
    auth0LogoutEndPoint: `${configuration.auth0Domain}/v2/logout?client_id=${configuration.profileManagementUiClientId}`,
  },
  featureToggleState: {
    flybuysFeatureToggle:
      configuration.featureToggleConfig.flybuysFeatureToggle,
    mfaMandatoryFeatureToggle:
      configuration.featureToggleConfig.mfaMandatoryFeatureToggle,
  },
  originLoginUrl: configuration.originLoginUrl,
};

export const appConfigSlice = createSlice({
  name: "appConfig",
  initialState,
  reducers: {
    setAppConfig: (state) => {},
  },
});

export const { setAppConfig } = appConfigSlice.actions;

export default appConfigSlice.reducer;
