import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationErrorCode } from "./ApplicationErrorCodes";

export interface ApplicationErrorState {
  errorCode: ApplicationErrorCode;
}

const initialState: ApplicationErrorState = {
  errorCode: ApplicationErrorCode.Unknown,
};

export const applicationErrorSlice = createSlice({
  name: "applicationError",
  initialState,
  reducers: {
    setErrorCode: (state, action: PayloadAction<ApplicationErrorCode>) => {
      state.errorCode = action.payload;
    },
  },
});

export const { setErrorCode } = applicationErrorSlice.actions;

export default applicationErrorSlice.reducer;
