import { Link, SuccessTickCircleRed } from "ccp-common-ui-components";
import styled from "styled-components";

export const StyledIcon = styled(SuccessTickCircleRed)`
  align-self: flex-start;
  margin: 2px 10px 0 0;
`;

export const StyledP = styled.ul`
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 0;
`;

export const StyledSpan = styled.span`
  flex: 1;
`;

export const StyledLink = styled(Link)`
  color: #e01a22;
`;

export const NoThanksLink = styled(Link)`
  font-weight: 600;
  font-size: 18px;
  color: #212121;
`;

export const LinkContainer = styled.div`
  text-align: center;
  margin-top: 16px;
`;

export const Description = styled.li`
  display: flex;
  align-items: start;
  justify-content:
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #3d3d3d;
  list-style-type: none;
  margin-bottom: 16px;
`;

export const StyledDescription = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #3d3d3d;
  margin-top: 24px;
`;

export const SmallerDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #3d3d3d;
`;

export const StyledCenteredDiv = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const FlybuysPrivacyUrl =
  "https://experience.flybuys.com.au/policies/privacy-policy/";
