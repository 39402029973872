import { call, put, select } from "redux-saga/effects";
import { logError } from "../../common/application-insights/AppInsights";
import {
  ComponentNames,
  FunctionNames,
} from "../../common/application-insights/Constants";
import { RootState } from "../../store/Store";
import { AppConfigState } from "../app-config/AppConfigSlice";

import { RouteState } from "../route/RouteSlice";
import {
  skipMobileEnrollmentSagaFailed,
  SkipMobileEnrollmentState,
  UpdateSkipMobileEnrollmentResponse,
} from "./SkipMobileEnrollmentSlice";
import { setContinueToken } from "../continue/ContinueSlice";
import { handleGenericErrorByStatusCode } from "../error/ErrorSlice";
import { linkFlybuysAction } from "../flybuys/FlybuysAction";
import { PayloadAction } from "@reduxjs/toolkit";
import { WithNav } from "../../utils/withNav";

export default function* SkipMobileEnrollmentSaga(
  action: PayloadAction<WithNav>
) {
  try {
    const { dontAskAgain }: SkipMobileEnrollmentState = yield select(
      (state: RootState) => state.skipMobileEnrollment
    );

    const { bffConfig }: AppConfigState = yield select(
      (state: RootState) => state.appConfig
    );

    const { jwtToken }: RouteState = yield select(
      (state: RootState) => state.route
    );

    const requestBody = { dontAskAgain: dontAskAgain };
    const response: Response = yield call(
      fetch,
      bffConfig.skipMobileEnrollmentEndPoint,
      {
        headers: { JwtToken: jwtToken, "Content-Type": "application/json" },
        method: "PATCH",
        body: JSON.stringify(requestBody),
      }
    );

    if (response.ok) {
      const successResponse: UpdateSkipMobileEnrollmentResponse = yield call([
        response,
        "json",
      ]);
      yield put(setContinueToken(successResponse.data.token));
      yield put(
        linkFlybuysAction({
          push: action.payload.push,
        })
      );
    } else {
      yield put(
        handleGenericErrorByStatusCode({
          statusCode: response.status,
          push: action.payload.push,
        })
      );
    }
  } catch (ex) {
    logError(
      new Error(ex.code),
      ComponentNames.SkipMobileEnrollmentSaga,
      FunctionNames.skipMobileEnrollmentSaga
    );
    yield put(skipMobileEnrollmentSagaFailed());
  }
}
