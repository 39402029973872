export enum ApplicationErrorCode {
  Unknown,
  LoginFailedError,
  ServiceUnavailableError,
  SecureLoginVerifyCodeExceeded,
  SecureLoginSendOtcExceeded,
  VerifyMobileError,
  VerifyMobileVerifyCodeExceeded,
  VerifyMobileSendOtcExceeded,
}
