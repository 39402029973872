import { call, select, put } from "redux-saga/effects";
import { RootState } from "../../store/Store";
import { AppConfigState } from "../app-config/AppConfigSlice";
import { continueRule, ContinueState } from "../continue/ContinueSlice";
import { setShowLoader } from "../loader/LoaderSlice";
import { OtcVerificationState } from "../mfa/OtcVerificationSlice";
import { RouteState } from "../route/RouteSlice";
import {
  FlybuysLinkingResponse,
  updateFlybuysLinkingState,
} from "./FlybuysSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { WithNav } from "../../utils/withNav";

export default function* flybuysSaga(action: PayloadAction<WithNav>) {
  try {
    const { bffConfig, featureToggleState }: AppConfigState = yield select(
      (state: RootState) => state.appConfig
    );

    const flybuysFeatureToggle = featureToggleState.flybuysFeatureToggle;
    if (!flybuysFeatureToggle) {
      yield put(continueRule());
      return;
    }

    const { jwtToken, auth0State }: RouteState = yield select(
      (state: RootState) => state.route
    );

    const { signedToken }: OtcVerificationState = yield select(
      (state: RootState) => state.otcVerification
    );

    const { continueToken }: ContinueState = yield select(
      (state: RootState) => state.continue
    );

    if (!bffConfig.baseUrl || !jwtToken) return;

    yield put(setShowLoader(true));

    const params = new URLSearchParams({
      state: auth0State,
      token: signedToken || continueToken,
    });

    const response: Response = yield call(
      fetch,
      `${bffConfig.getFlybuysLinkedEndPoint}?${params}`,
      {
        headers: { JwtToken: jwtToken },
        method: "GET",
        mode: "cors",
        credentials: "include",
      }
    );

    if (!response.ok) {
      yield put(continueRule());
      return;
    }

    const successResponse: FlybuysLinkingResponse = yield call([
      response,
      "json",
    ]);

    yield put(setShowLoader(false));

    if (!successResponse.linkFlybuysUrl) {
      yield put(continueRule());
      return;
    }

    yield put(updateFlybuysLinkingState(successResponse));

    const route = successResponse.relink ? "/relinkFlybuys" : "/linkFlybuys";

    action.payload.push(route);
  } catch {
    yield put(continueRule());
  }
}
