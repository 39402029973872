import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WithNav } from "../../utils/withNav";

export interface SendOtcState {
  sentTo: string;
  errorMessage: string;
  channel: string;
  maxRetryReached: boolean;
  preVerification: boolean;
  smsGatewayUnavailable: boolean;
}

const initialState: SendOtcState = {
  sentTo: "",
  errorMessage: "",
  channel: "sms",
  maxRetryReached: false,
  preVerification: true,
  smsGatewayUnavailable: false,
};

export interface SendMfaCodeResponse {
  data: SendMfaCodeData;
}

export interface SendMfaCodeData {
  sentTo: string;
  channel: string;
  canFlags: MfaCanFlags;
}

export interface MfaCanFlags {
  canSendCode: boolean;
  canVerifyCode: boolean;
}

export interface SendMfaCodeErrorResponse {
  error: SendMfaCodeError;
}

export interface SendMfaCodeError {
  canSendCode: boolean;
  canVerifyCode: boolean;
}

export const sendOtcSlice = createSlice({
  name: "sendOtc",
  initialState,
  reducers: {
    sendOtc: (state, _action: PayloadAction<WithNav>) => {
      state.errorMessage = "";
    },
    sentOtcSuccessfully: (
      state,
      action: PayloadAction<SendMfaCodeResponse>
    ) => {
      state.sentTo = action.payload.data.sentTo;
    },
    sendOtcFailed: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    sendOtcMaxRetryReached: (state) => {
      state.maxRetryReached = true;
    },
    setChannel: (state, action: PayloadAction<string>) => {
      state.channel = action.payload;
    },
    unsetPreVerification: (state) => {
      state.preVerification = false;
    },
    setPreVerification: (state) => {
      state.preVerification = true;
    },
    smsGatewayIsUnavailable: (state) => {
      state.smsGatewayUnavailable = true;
    },
  },
});

export const {
  sendOtc,
  sentOtcSuccessfully,
  sendOtcFailed,
  sendOtcMaxRetryReached,
  setChannel,
  unsetPreVerification,
  setPreVerification,
  smsGatewayIsUnavailable,
} = sendOtcSlice.actions;

export default sendOtcSlice.reducer;
