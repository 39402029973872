export enum ComponentNames {
  App = "App",
  OtcVerificationSaga = "OtcVerificationSaga",
  PersonalizationDetailsSaga = "PersonalizationDetailsSaga",
  SendOtcSaga = "SendOtcSaga",
  RouteSaga = "RouteSaga",
  SaveMobileNumberSaga = "SaveMobileNumberSaga",
  SkipMobileEnrollmentSaga = "SkipMobileEnrollmentSaga",
  VerifyLaterSaga = "VerifyLaterSaga",
  AccountLinkagesSaga = "AccountLinkagesSaga",
  MigrationsSaga = "MigrationsSaga",
  PreferencesSaga = "PreferencesSaga",
  FlybuysPreferencesSaga = "FlybuysPreferencesSaga",
  BusinessAttributesSaga = "BusinessAttributesSaga",
}

export enum FunctionNames {
  continueSaga = "continueSaga",
  otcVerificationSaga = "otcVerificationSaga",
  personalizationSaga = "personalizationSaga",
  sendOtcSaga = "sendOtcSaga",
  routeSaga = "routeSaga",
  saveMobileNumberSaga = "saveMobileNumberSaga",
  skipMobileEnrollmentSaga = "skipMobileEnrollmentSaga",
  verifyLaterSaga = "verifyLaterSaga",
  accountLinkagesSaga = "accountLinkagesSaga",
  migrationsSaga = "migrationsSaga",
  preferencesSaga = "preferencesSaga",
  flybuysPreferencesSaga = "flybuysPreferencesSaga",
  businessAttributesSaga = "businessAttributesSaga",
}

export enum ErrorCodes {
  RequestThrottled = "RequestThrottled",
}
