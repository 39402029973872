import { select, put } from "redux-saga/effects";
import { RootState } from "../../store/Store";
import { setErrorCode } from "../application-error/ApplicationErrorSlice";
import { ApplicationErrorCode } from "../application-error/ApplicationErrorCodes";
import { PayloadAction } from "@reduxjs/toolkit";
import { WithNav } from "../../utils/withNav";

export default function* errorSaga(action: PayloadAction<WithNav>) {
  const { statusCode } = yield select((state: RootState) => state.error);

  if (statusCode === 401) {
    yield put(setErrorCode(ApplicationErrorCode.LoginFailedError));
  } else {
    yield put(setErrorCode(ApplicationErrorCode.ServiceUnavailableError));
  }
  action.payload.push("/genericError");
}
