export const mfaVerificationFailed = "Enter a valid 6-digit code e.g. 123456";

export const mfaVerificationFailedGeneric = "Something went wrong.";

export const oneTimeCodeIsRequired = "Enter a 6-digit code e.g. 123456";

export const invalidOneTimeCode = "Enter a valid 6-digit code e.g. 123456";

export const mobileNumberIsRequired = "Enter a mobile number";

export const invalidMobileNumber =
  "Enter a valid mobile number e.g. 0411 222 333";

export const sendOrVerifyOtcExceededErrorText =
  "You've reached the maximum limit for one-time code requests. Wait 5 minutes to request a new code.";

export const otcEnteredTooManyTimesErrorText =
  "You entered in a code too many times.";

export const invalidDateOfBirthError =
  "Enter a valid date of birth e.g. 02/12/1970";
