import { VerifyFlybuysLinkingResponse } from "./FlybuysSlice";

export class FlybuysVerifyError extends Error implements IFlybuysVerifyError {
  state?: string;
  token?: string;
  constructor(state?: string, token?: string) {
    super();
    this.state = state;
    this.token = token;
  }
}

export interface FlybuysVerifyErrorResponseData {
  state: string;
  token: string;
}

export interface IFlybuysVerifyError {
  state?: string;
  token?: string;
}

export function getFlybuysVerifyErrorResponse(
  error: unknown
): VerifyFlybuysLinkingResponse {
  const verifyFlybuysDefaultResponse: VerifyFlybuysLinkingResponse = {
    state: "",
    token: "",
  };

  try {
    if (error instanceof FlybuysVerifyError) {
      const verifyFlybuysResponse: VerifyFlybuysLinkingResponse = {
        state: error.state || "",
        token: error.token || "",
      };
      return verifyFlybuysResponse;
    }
    return verifyFlybuysDefaultResponse;
  } catch {
    return verifyFlybuysDefaultResponse;
  }
}
