import { Heading1New, Link, ThemedButtonNew } from "ccp-common-ui-components";
import styled from "styled-components";

export const Header = styled(Heading1New)`
  margin-bottom: 24px;
`;

export const Description = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #3d3d3d;
`;

export const RelinkButton = styled(ThemedButtonNew)`
  margin-top: 24px;
`;

export const LinkContainer = styled.div`
  text-align: center;
  margin-top: 16px;
`;

export const SkipLink = styled(Link)`
  font-weight: 600;
  font-size: 18px;
  color: #212121;
`;
