export const industryOptions = [
  "Childcare",
  "Schools or further education",
  "Aged care",
  "Disability care",
  "Health or medical",
  "Sports or recreation clubs",
  "Charity or community group",
  "Government",
  "Hospitality",
  "Office-based",
  "Construction or real estate",
  "Retail, wholesale or distribution",
  "Shipping or mining",
  "Other",
];

export const mobileNumberUrl = "/mobileNumber";

export enum ProfileRoute {
  MfaMobileExempt = "mfamobileexempt",
}
