import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WithNav } from "../../utils/withNav";

const initialState = {};

export const verifyLaterSlice = createSlice({
  name: "verifyLater",
  initialState,
  reducers: {
    verifyLater: (_state, _action: PayloadAction<WithNav>) => {},
  },
});

export const { verifyLater } = verifyLaterSlice.actions;

export default verifyLaterSlice.reducer;
