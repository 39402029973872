import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WithNav } from "../../utils/withNav";

export interface ErrorState {
  statusCode?: number;
}

const initialState: ErrorState = {};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    handleGenericErrorByStatusCode: (
      state,
      action: PayloadAction<WithNav<ErrorState>>
    ) => {
      state.statusCode = action.payload.statusCode;
    },
  },
});

export const { handleGenericErrorByStatusCode } = errorSlice.actions;

export default errorSlice.reducer;
