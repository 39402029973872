import { logError } from "../../common/application-insights/AppInsights";
import {
  ComponentNames,
  FunctionNames,
} from "../../common/application-insights/Constants";
import { AppConfigState } from "../app-config/AppConfigSlice";
import { RouteState } from "../route/RouteSlice";

import { call, select } from "redux-saga/effects";
import { RootState } from "../../store/Store";
import { PayloadAction } from "@reduxjs/toolkit";

export default function* migrationsSaga(action: PayloadAction<string>) {
  try {
    const { bffConfig }: AppConfigState = yield select(
      (state: RootState) => state.appConfig
    );

    const { jwtToken }: RouteState = yield select(
      (state: RootState) => state.route
    );

    if (!bffConfig.baseUrl || !jwtToken) return;

    const clientName = action.payload;

    const requestBody = {
      clientName,
    };

    yield call(fetch, bffConfig.migrationEndPoint, {
      headers: { JwtToken: jwtToken, "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify(requestBody),
      mode: "cors",
    });
  } catch (e) {
    logError(
      new Error(e.code),
      ComponentNames.MigrationsSaga,
      FunctionNames.migrationsSaga
    );
  }
}
