import { logError } from "../../common/application-insights/AppInsights";
import {
  ComponentNames,
  FunctionNames,
} from "../../common/application-insights/Constants";
import { AppConfigState } from "../app-config/AppConfigSlice";
import { RouteState } from "../route/RouteSlice";

import { call, select, put } from "redux-saga/effects";

import { RootState } from "../../store/Store";
import { PayloadAction } from "@reduxjs/toolkit";
import { BusinessAttributesInputs } from "./BusinessAttributesPage";

import { setShowLoader } from "../loader/LoaderSlice";
import { WithNav } from "../../utils/withNav";

export default function* businessAttributesSaga(
  action: PayloadAction<WithNav<BusinessAttributesInputs>>
) {
  try {
    const { bffConfig }: AppConfigState = yield select(
      (state: RootState) => state.appConfig
    );

    const { jwtToken }: RouteState = yield select(
      (state: RootState) => state.route
    );

    if (!bffConfig.baseUrl || !jwtToken) return;

    const cleanAbn = action.payload.abn
      ? action.payload.abn.replace(/[^\d]/g, "")
      : action.payload.abn;

    const requestBody = {
      businessName: action.payload.businessName,
      abn: cleanAbn,
      industryType: action.payload.industryType,
    };

    yield put(setShowLoader(true));

    const response: Response = yield call(
      fetch,
      bffConfig.businessAttributesEndpoint,
      {
        headers: { JwtToken: jwtToken, "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify(requestBody),
        mode: "cors",
      }
    );

    yield put(setShowLoader(false));

    action.payload.push("/mobileNumber");
  } catch (e) {
    logError(
      new Error((e as { code: string }).code),
      ComponentNames.BusinessAttributesSaga,
      FunctionNames.businessAttributesSaga
    );
  }
}
