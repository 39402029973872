import { useHistory } from "react-router-dom";

export type PushType = ReturnType<typeof useHistory>["push"];

export type WithNav<T = {}> = T & {
  push: PushType;
};

export function useWithNav<T>() {
  const { push } = useHistory();
  return (data?: T) => (data ? { ...data, push } : ({ push } as WithNav<T>));
}
