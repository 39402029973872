import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./RootReducer";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./RootSaga";
import { createBrowserHistory } from "history";
import { TypedUseSelectorHook, useSelector } from "react-redux";
export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

export const store = configureStore({
  reducer: rootReducer(),
  middleware: middleware,
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
