import { clearCookies } from "./cookies";

export const redirectToUrl = (url: string) => {
  window.location.assign(url);
};

export const auth0Logout = (logoutUrl: string) => {
  clearCookies();
  redirectToUrl(logoutUrl);
};
