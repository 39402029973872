import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Heading1New, ThemedButtonNew } from "ccp-common-ui-components";
import {
  LinkContainer,
  SkipLink,
  StyledCenteredDiv,
  StyledDescription,
} from "./FlybuysSkipPromptStyles";
import { redirectToUrl } from "../../common/utils/navigation";
import { RootState } from "../../store/Store";
import { DontAskAgainCheckBox } from "./DontAskAgainCheckBox";
import { flybuysPreferencesAction } from "./FlybuysAction";

function FlybuysSkipPromptPage() {
  const { linkFlybuysUrl } = useSelector(
    (state: RootState) => state.linkFlybuys
  );

  const [skipPrompt, setSkipPrompt] = useState(false);

  const dispatch = useDispatch();

  return (
    <>
      <Heading1New>Are you sure you want to skip?</Heading1New>

      <StyledDescription>
        Linking your account ensures you won't need to link it again for each of
        your individual Coles Group accounts.
      </StyledDescription>

      <StyledDescription>
        You can also link it anytime from your My Coles Group account.
      </StyledDescription>

      <DontAskAgainCheckBox
        checked={skipPrompt}
        id="dont-ask-again"
        data-testid="dont-ask-again"
        onChange={(isChecked) => {
          setSkipPrompt(isChecked);
        }}
      />

      <StyledCenteredDiv>
        <ThemedButtonNew
          isFullWidth={true}
          onClick={() => {
            redirectToUrl(linkFlybuysUrl);
          }}
        >
          Link my flybuys
        </ThemedButtonNew>
      </StyledCenteredDiv>
      <LinkContainer>
        <SkipLink
          href=""
          onClick={(e) => {
            e.preventDefault();
            dispatch(flybuysPreferencesAction({ skipFlybuys: skipPrompt }));
          }}
        >
          Continue
        </SkipLink>
      </LinkContainer>
    </>
  );
}

export default FlybuysSkipPromptPage;
