import React, { useEffect } from "react";
import "./App.css";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import { getRoute, RouteRequest } from "./features/route/RouteSlice";

import { initialiseAppInsights } from "./common/application-insights/AppInsights";
import Configuration from "./common/config/Configuration";

import { RootState, useAppSelector } from "./store/Store";
import { UserStepContainer } from "./UserStepContainer";
import Switcher from "./Switcher";
import { ColesClientName } from "ccp-common-ui-components";
import { accountLinkages } from "./features/account-linkages/AccountLinkagesSlice";
import { setClientName } from "./features/channel/ChannelSlice";
import { migrations } from "./features/migrations/MigrationsSlice";
import { useWithNav } from "./utils/withNav";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App() {
  const dispatch = useDispatch();

  const { showLoader } = useAppSelector((state) => state.loader);
  const { continueToken } = useAppSelector((state) => state.continue);

  const { statusCode } = useAppSelector((state) => state.error);

  const auth0State = useQuery().get("state") ?? "";
  const token = useQuery().get("token") ?? "";
  const adobeMc = useQuery().get("adobe_mc") ?? "";
  const clientName = (useQuery().get("clientName") as ColesClientName) ?? "";

  const history = useHistory();
  const withNav = useWithNav<RouteRequest>();

  useEffect(() => {
    if (clientName) {
      dispatch(setClientName(clientName));
    }
  }, []);

  useEffect(() => {
    const hasAuth0QueryParameters = auth0State && token;
    const hasNoApiError = !statusCode;
    if (hasAuth0QueryParameters && !continueToken && hasNoApiError) {
      const routeRequest: RouteRequest = { auth0State, token, adobeMc };
      dispatch(getRoute(withNav(routeRequest)));
    }
  }, [adobeMc, auth0State, continueToken, dispatch, statusCode, token]);

  const { flybuysFeatureToggle } = useSelector(
    (state: RootState) => state.appConfig.featureToggleState
  );

  const { pathname } = useLocation();

  const cookieCompatibleRoutes = ["/callback/flybuys"];

  useEffect(() => {
    if (cookieCompatibleRoutes.includes(pathname)) {
      return;
    }

    const missingAuth0QueryParameters = !auth0State || !token;
    const hasNoApiError = !statusCode;

    if (missingAuth0QueryParameters && !continueToken && hasNoApiError) {
      history.replace("/serverError");
    }
  }, [auth0State, token, statusCode, continueToken, dispatch, history]);

  useEffect(() => {
    const hasAuth0QueryParameters = auth0State && token;
    const hasNoApiError = !statusCode;

    if (hasAuth0QueryParameters && !continueToken && hasNoApiError) {
      dispatch(accountLinkages(clientName));
    }
  }, [auth0State, continueToken, dispatch, statusCode, token]);

  useEffect(() => {
    const hasAuth0QueryParameters = auth0State && token;
    const hasNoApiError = !statusCode;

    if (hasAuth0QueryParameters && !continueToken && hasNoApiError) {
      dispatch(migrations(clientName));
    }
  }, [auth0State, continueToken, dispatch, statusCode, token]);

  initialiseAppInsights(Configuration.appInsightsInstrumentationKey);

  return (
    <AppContainer>
      <UserStepContainer showLoader={showLoader}>
        <Switcher flybuysFeatureToggle={flybuysFeatureToggle} />
      </UserStepContainer>
    </AppContainer>
  );
}

export default App;

const AppContainer = styled.div`
  display: flex;
  height: 100%;
  font-family: "Source Sans", sans-serif;

  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    height: max-content;
    width: 100%;
  }
`;
