import {
  ApplicationInsights,
  IExceptionTelemetry,
} from "@microsoft/applicationinsights-web";

export const initialiseAppInsights = (instrumentationKey: string) => {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: instrumentationKey,
      disableFetchTracking: false,
      autoTrackPageVisitTime: true,
    },
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView();
  window.appInsights = appInsights;
};

export const logError = (
  error: Error,
  component: string,
  functionName: string,
  properties: {
    [key: string]: any;
  } = {}
) => {
  properties["component"] = component;
  properties["functionName"] = functionName;

  const exceptionTelemetry: IExceptionTelemetry = {
    properties: properties,
    error: error,
  };

  window.appInsights.trackException(exceptionTelemetry);
};
