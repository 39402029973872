import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WithNav } from "../../utils/withNav";

export interface OtcVerificationState {
  oneTimeCode: string;
  otcVerificationErrorMessage: string;
  signedToken: string;
}

const initialState: OtcVerificationState = {
  oneTimeCode: "",
  otcVerificationErrorMessage: "",
  signedToken: "",
};

export interface VerifyCodeSuccessResponse {
  data: VerifyCodeData;
}

export interface VerifyCodeData {
  verified: boolean;
  token: string;
  canFlags: MfaCanFlags;
}

export interface MfaCanFlags {
  canSendCode: boolean;
  canVerifyCode: boolean;
}

export interface VerifyCodeErrorResponse {
  error: VerifyCodeError;
}

export interface VerifyCodeError {
  canSendCode: boolean;
  canVerifyCode: boolean;
}

export interface SecureLoginInputs {
  oneTimeCode: string;
}

export const otcVerificationSlice = createSlice({
  name: "otcVerification",
  initialState,
  reducers: {
    verifyOtc: (state, action: PayloadAction<WithNav<SecureLoginInputs>>) => {
      state.oneTimeCode = action.payload.oneTimeCode;
    },
    verifiedOtcSuccessfully: (state, action: PayloadAction<string>) => {
      state.signedToken = action.payload;
    },
    verifiedOtcFailed: (state, action: PayloadAction<string>) => {
      state.otcVerificationErrorMessage = action.payload;
    },
    clearOtcVerificationErrorMessage: (state) => {
      state.otcVerificationErrorMessage = "";
    },
  },
});

export const {
  verifyOtc,
  verifiedOtcSuccessfully,
  verifiedOtcFailed,
  clearOtcVerificationErrorMessage,
} = otcVerificationSlice.actions;

export default otcVerificationSlice.reducer;
