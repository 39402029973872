import { PayloadAction } from "@reduxjs/toolkit";
import { put } from "redux-saga/effects";
import { logError } from "../../common/application-insights/AppInsights";
import {
  ComponentNames,
  FunctionNames,
} from "../../common/application-insights/Constants";
import { continueRule } from "../continue/ContinueSlice";
import { preferencesAction } from "../preferences/PreferencesAction";
import { FlybuysPreferencesAction } from "./FlybuysSlice";

export default function* flybuysPreferencesSaga(
  action: PayloadAction<FlybuysPreferencesAction>
) {
  try {
    const name = "skipFlybuys";
    const value = action.payload.skipFlybuys;

    if (value) {
      yield put(preferencesAction({ name, value }));
    }

    yield put(continueRule());
  } catch (ex) {
    yield put(continueRule());
    logError(
      new Error(ex.code),
      ComponentNames.FlybuysPreferencesSaga,
      FunctionNames.flybuysPreferencesSaga
    );
  }
}
