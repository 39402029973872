import { call, select, put } from "redux-saga/effects";
import { RootState } from "../../store/Store";
import { AppConfigState } from "../app-config/AppConfigSlice";
import { setShowLoader } from "../loader/LoaderSlice";
import { sendOtc, setChannel } from "../mfa/SendOtcSlice";
import {
  RouteRequest,
  RouteResponse,
  RouteState,
  gotRouteSuccessfully,
} from "./RouteSlice";
import { setContinueToken } from "../continue/ContinueSlice";
import { logError } from "../../common/application-insights/AppInsights";
import {
  ComponentNames,
  FunctionNames,
} from "../../common/application-insights/Constants";
import { setMobileNumber } from "../verify-mobile/MobileNumberSlice";
import {
  setIsB2BUserFlag,
  setIsB2BUserSigningUpFlag,
} from "../verify-mobile/ProfileTypeSlice";
import { handleGenericErrorByStatusCode } from "../error/ErrorSlice";
import { linkFlybuysAction } from "../flybuys/FlybuysAction";
import { WithNav } from "../../utils/withNav";
import { PayloadAction } from "@reduxjs/toolkit";
import { ProfileRoute } from "../../common/constants";
import { isRouteMfaMobileExempt } from "../../common/commonFunctions";

export default function* routeSaga(
  action: PayloadAction<WithNav<RouteRequest>>
) {
  try {
    const { bffConfig, featureToggleState }: AppConfigState = yield select(
      (state: RootState) => state.appConfig
    );

    const { jwtToken }: RouteState = yield select(
      (state: RootState) => state.route
    );

    if (!bffConfig.baseUrl || !jwtToken) return;

    yield put(setShowLoader(true));

    const response: Response = yield call(fetch, bffConfig.routeEndPoint, {
      headers: { JwtToken: jwtToken },
      method: "GET",
      mode: "cors",
    });

    if (response.ok) {
      const successResponse: RouteResponse = yield call([response, "json"]);

      yield put(gotRouteSuccessfully(successResponse));
      yield put(setContinueToken(successResponse.data.token));

      if (successResponse.data.mobile !== "") {
        yield put(setMobileNumber(successResponse.data.mobile));
      }

      yield put(setIsB2BUserFlag(successResponse.data.isB2BUser));

      if (isRouteMfaMobileExempt(successResponse.data.route)) {
        yield put(setChannel("email"));
        yield put(
          sendOtc({
            push: action.payload.push,
          })
        );
      } else if (
        successResponse.data.route.toLowerCase() === "mfa" &&
        successResponse.data.mobile !== ""
      ) {
        // TODO: Show the verify page before sending OTC otherwise unhandled BFF errors (e.g. 400) might trigger an empty page
        yield put(
          sendOtc({
            push: action.payload.push,
          })
        );
      } else if (
        successResponse.data.route.toLowerCase() === "personalisationb2b"
      ) {
        yield put(setIsB2BUserSigningUpFlag(true));
        action.payload.push("/businessattributes");
      } else if (successResponse.data.route.toLowerCase() === "verifymobile") {
        action.payload.push("/mobileNumber");
      } else if (
        successResponse.data.route.toLowerCase() === "auth0-continue" &&
        successResponse.data.token !== ""
      ) {
        yield put(
          linkFlybuysAction({
            push: action.payload.push,
          })
        );
      }
    } else {
      yield put(
        handleGenericErrorByStatusCode({
          push: action.payload.push,
          statusCode: response.status,
        })
      );
    }
  } catch (e) {
    logError(
      new Error(e.code),
      ComponentNames.RouteSaga,
      FunctionNames.routeSaga
    );
    yield put(setShowLoader(false));
  }
}
