import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Heading1New,
  ThemedButtonNew,
  ErrorCrossCircleNew,
} from "ccp-common-ui-components";
import styled from "styled-components";
import { continueRule } from "../continue/ContinueSlice";
import { RootState } from "../../store/Store";
import {
  getErrorMessage,
  getErrorTitle,
} from "../application-error/ApplicationErrorUtils";
import { dataLayerGenericErrorPage } from "../../tracking/tracking";

const GenericErrorPage = function () {
  const dispatch = useDispatch();

  const clientName = useSelector(
    (state: RootState) => state.channel.clientName
  );

  useEffect(() => {
    if (clientName) {
      dataLayerGenericErrorPage(clientName);
    }
  }, [clientName]);

  const { errorCode } = useSelector(
    (state: RootState) => state.applicationError
  );

  const errorMessage = getErrorMessage(errorCode);
  const title = getErrorTitle(errorCode);

  return (
    <MainDiv>
      <ErrorCrossCircleNew role="presentation" />
      <Heading1New>{title}</Heading1New>
      <Message>{errorMessage}</Message>
      <StyledButton
        data-testid="back-button"
        onClick={() => {
          dispatch(continueRule());
        }}
      >
        Back to login screen
      </StyledButton>
    </MainDiv>
  );
};

const MainDiv = styled.div`
  margin: 40px 10px;
  text-align: center;
`;

const Message = styled.p`
  margin-bottom: 40px;
`;

const StyledButton = styled(ThemedButtonNew)`
  width: 100%;
`;

export default GenericErrorPage;
