import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FlybuysLinkingResponse {
  linkFlybuysUrl: string;
  registerFlybuysUrl: string;
  relink: boolean;
}

export interface FlybuysLinkingState {
  linkFlybuysUrl: string;
  registerFlybuysUrl: string;
  relink: boolean;
}

export interface VerifyFlybuysLinkingRequest {
  code: string;
  state: string;
  userCancelled: boolean;
}

export interface VerifyFlybuysLinkingResponse {
  state: string;
  token: string;
}

export interface FlybuysPreferencesAction {
  skipFlybuys: boolean;
}

export const initialState: FlybuysLinkingState = {
  linkFlybuysUrl: "",
  registerFlybuysUrl: "",
  relink: false,
};

export const flybuysSlice = createSlice({
  name: "flybuysSlice",
  initialState,
  reducers: {
    updateFlybuysLinkingState: (
      state,
      action: PayloadAction<FlybuysLinkingResponse>
    ) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateFlybuysLinkingState } = flybuysSlice.actions;

export const flybuysReducer = flybuysSlice.reducer;
