import { takeEvery, takeLeading } from "redux-saga/effects";

import { getRoute } from "../features/route/RouteSlice";
import routeSaga from "../features/route/RouteSaga";
import { verifyOtc } from "../features/mfa/OtcVerificationSlice";
import sendOtcSaga from "../features/mfa/SendOtcSaga";
import otcVerificationSaga from "../features/mfa/OtcVerificationSaga";
import { sendOtc } from "../features/mfa/SendOtcSlice";
import saveMobileNumberSaga from "../features/verify-mobile/SaveMobileNumberSaga";
import { saveMobileNumber } from "../features/verify-mobile/MobileNumberSlice";
import { continueRule } from "../features/continue/ContinueSlice";
import continueSaga from "../features/continue/ContinueSaga";
import verifyLaterSaga from "../features/verify-later/VerifyLaterSaga";
import { verifyLater } from "../features/verify-later/VerifyLaterSlice";
import skipMobileEnrollmentSaga from "../features/skip-mobile-enrollment/SkipMobileEnrollmentSaga";
import { skipMobileEnrollment } from "../features/skip-mobile-enrollment/SkipMobileEnrollmentSlice";
import { handleGenericErrorByStatusCode } from "../features/error/ErrorSlice";
import errorSaga from "../features/error/ErrorSaga";
import { accountLinkages } from "../features/account-linkages/AccountLinkagesSlice";
import accountLinkagesSaga from "../features/account-linkages/AccountLinkagesSaga";
import flybuysSaga from "../features/flybuys/FlybuysSaga";
import {
  flybuysPreferencesAction,
  linkFlybuysAction,
  verifyFlybuysLinkingAction,
} from "../features/flybuys/FlybuysAction";
import verifyFlybuysAccountSaga from "../features/flybuys/VerifyFlybuysAccountSaga";
import migrationsSaga from "../features/migrations/MigrationsSaga";
import { migrations } from "../features/migrations/MigrationsSlice";
import preferencesSaga from "../features/preferences/PreferencesSaga";
import flybuysPreferencesSaga from "../features/flybuys/FlybuysPreferenceSaga";
import { preferencesAction } from "../features/preferences/PreferencesAction";
import businessPreferencesSaga from "../features/business/BusinessAttributesSaga";
import { postBusinessAttributes } from "../features/business/BusinessAttributesSlice";

export default function* rootSaga() {
  yield takeEvery(getRoute, routeSaga);
  yield takeEvery(sendOtc, sendOtcSaga);
  yield takeEvery(verifyOtc, otcVerificationSaga);
  yield takeEvery(saveMobileNumber, saveMobileNumberSaga);
  yield takeLeading(continueRule, continueSaga);
  yield takeEvery(skipMobileEnrollment, skipMobileEnrollmentSaga);
  yield takeEvery(verifyLater, verifyLaterSaga);
  yield takeEvery(handleGenericErrorByStatusCode, errorSaga);
  yield takeEvery(accountLinkages, accountLinkagesSaga);
  yield takeEvery(linkFlybuysAction, flybuysSaga);
  yield takeEvery(verifyFlybuysLinkingAction, verifyFlybuysAccountSaga);
  yield takeEvery(migrations, migrationsSaga);
  yield takeEvery(preferencesAction, preferencesSaga);
  yield takeEvery(flybuysPreferencesAction, flybuysPreferencesSaga);
  yield takeEvery(postBusinessAttributes, businessPreferencesSaga);
}
