import React from "react";
import styled from "styled-components";
import ThemedCheckBox from "../../common/inputs/ThemedCheckBox";

export interface DontAskAgainCheckBoxProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  onChange(boolChecked: boolean): void;
}

const DontAskAgainCheckBoxDiv = styled.div`
  display: flex;
`;

export const DontAskAgainCheckBox = React.forwardRef(
  (props: DontAskAgainCheckBoxProps, ref: React.Ref<HTMLInputElement>) => {
    const { onChange, ...rest } = props;
    return (
      <DontAskAgainCheckBoxDiv>
        <ThemedCheckBox
          {...rest}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
          ref={ref}
          name="dont-ask-again"
        />
        <label htmlFor="dont-ask-again">
          Don't ask me to link my flybuys account again
        </label>
      </DontAskAgainCheckBoxDiv>
    );
  }
);
