import { AppConfigState } from "../app-config/AppConfigSlice";
import { call, put, select } from "redux-saga/effects";
import { RootState } from "../../store/Store";
import {
  setContinueToken,
  VerifyLaterResponse,
} from "../continue/ContinueSlice";
import { RouteState } from "../route/RouteSlice";
import { logError } from "../../common/application-insights/AppInsights";
import {
  ComponentNames,
  FunctionNames,
} from "../../common/application-insights/Constants";
import { linkFlybuysAction } from "../flybuys/FlybuysAction";
import { WithNav } from "../../utils/withNav";
import { PayloadAction } from "@reduxjs/toolkit";

export default function* VerifyLaterSaga(action: PayloadAction<WithNav>) {
  try {
    const { bffConfig }: AppConfigState = yield select(
      (state: RootState) => state.appConfig
    );

    const { jwtToken }: RouteState = yield select(
      (state: RootState) => state.route
    );

    const response: Response = yield call(
      fetch,
      bffConfig.verifyLaterEndPoint,
      {
        headers: { JwtToken: jwtToken },
        method: "GET",
        mode: "cors",
      }
    );

    if (response.ok) {
      const successResponse: VerifyLaterResponse = yield call([
        response,
        "json",
      ]);

      if (successResponse.data.token) {
        yield put(setContinueToken(successResponse.data.token));
        yield put(
          linkFlybuysAction({
            push: action.payload.push,
          })
        );
      } else {
        return;
      }
    }
  } catch (e) {
    logError(
      new Error(e.code),
      ComponentNames.VerifyLaterSaga.toString(),
      FunctionNames.verifyLaterSaga.toString()
    );
  }
}
