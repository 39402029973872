import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { continueRule, ContinueState } from "../continue/ContinueSlice";
import { setShowLoader } from "../loader/LoaderSlice";
import {
  ErrorCrossCircleNew,
  Heading1New,
  ThemedButtonNew,
} from "ccp-common-ui-components";
import { auth0Logout } from "../../common/utils/navigation";
import { RootState } from "../../store/Store";
import { AppConfigState } from "../app-config/AppConfigSlice";
import { RouteState } from "../route/RouteSlice";
import { dataLayerFlybuysLinkingFailedPage } from "../../tracking/tracking";

const FlybuysLinkingFailedPage = () => {
  const dispatch = useDispatch();

  const { auth0Config }: AppConfigState = useSelector(
    (state: RootState) => state.appConfig
  );

  const { auth0State }: RouteState = useSelector(
    (state: RootState) => state.route
  );

  const { continueToken }: ContinueState = useSelector(
    (state: RootState) => state.continue
  );

  const clientName = useSelector(
    (state: RootState) => state.channel.clientName
  );

  useEffect(() => {
    if (clientName) {
      dataLayerFlybuysLinkingFailedPage(clientName);
    }
  }, [clientName]);

  useEffect(() => {
    dispatch(setShowLoader(false));
  }, []);

  function contunueOnClickButton(e: React.MouseEvent) {
    e.preventDefault();
    if (!auth0State || !continueToken) {
      auth0Logout(auth0Config.auth0LogoutEndPoint);
      return;
    }

    dispatch(continueRule());
  }

  return (
    <Container>
      <ErrorCrossCircleNew data-testid="error-icon" role="presentation" />
      <Header>Something went wrong</Header>
      <Description>
        We're unable to link your flybuys account right now.
      </Description>
      <Description>
        You can link your flybuys account from within your Coles account,
        otherwise we will remind you when you sign in next time.
      </Description>
      <ThemedButtonNew
        isFullWidth={true}
        onClick={(e) => {
          contunueOnClickButton(e);
        }}
      >
        Continue
      </ThemedButtonNew>
    </Container>
  );
};

export default FlybuysLinkingFailedPage;

const Container = styled.div`
  text-align: center;
`;

const Header = styled(Heading1New)`
  margin: 28px 0 16px 0;
`;

const Description = styled.p`
  margin-bottom: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`;
