import { Link } from "ccp-common-ui-components";
import React from "react";
import styled from "styled-components";

const PrivacyPolicy = styled(Link)`
  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    padding: 0px 24px;
  }
`;

export default function PrivacyPolicyLink() {
  return (
    <PrivacyPolicy
      data-testid="footer-privacy-policy-link"
      href="https://www.coles.com.au/important-information/privacy-policy"
      target="_blank"
    >
      Coles Group Privacy Policy
    </PrivacyPolicy>
  );
}
