import { combineReducers } from "@reduxjs/toolkit";
import otcVerificationReducer from "../features/mfa/OtcVerificationSlice";
import mobileNumberReducer from "../features/verify-mobile/MobileNumberSlice";
import profileTypeReducer from "../features/verify-mobile/ProfileTypeSlice";
import loaderReducer from "../features/loader/LoaderSlice";
import routeReducer from "../features/route/RouteSlice";
import sendMfaCodeReducer from "../features/mfa/SendOtcSlice";
import appConfigReducer from "../features/app-config/AppConfigSlice";
import continueReducer from "../features/continue/ContinueSlice";
import skipMobileEnrollmentReducer from "../features/skip-mobile-enrollment/SkipMobileEnrollmentSlice";
import verifyLaterReducer from "../features/verify-later/VerifyLaterSlice";
import errorReducer from "../features/error/ErrorSlice";
import channelReducer from "../features/channel/ChannelSlice";
import applicationErrorReducer from "../features/application-error/ApplicationErrorSlice";
import { flybuysReducer } from "../features/flybuys/FlybuysSlice";
import businessAttributesReducer from "../features/business/BusinessAttributesSlice";

const rootReducer = () =>
  combineReducers({
    appConfig: appConfigReducer,
    otcVerification: otcVerificationReducer,
    mobileNumber: mobileNumberReducer,
    profileType: profileTypeReducer,
    skipMobileEnrollment: skipMobileEnrollmentReducer,
    continue: continueReducer,
    loader: loaderReducer,
    route: routeReducer,
    sendOtc: sendMfaCodeReducer,
    verifyLater: verifyLaterReducer,
    error: errorReducer,
    channel: channelReducer,
    applicationError: applicationErrorReducer,
    linkFlybuys: flybuysReducer,
    postBusinessAttributes: businessAttributesReducer,
  });

export default rootReducer;
