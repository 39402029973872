import React, { useEffect } from "react";
import {
  ErrorText,
  Heading1New,
  InputFieldNew,
  InputLabel,
  SecureLoginIconNew,
  ThemedButtonNew,
} from "ccp-common-ui-components";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import {
  clearMobileVerificationErrorMessage,
  MobileInput,
  saveMobileNumber,
} from "./MobileNumberSlice";
import { setShowLoader } from "../loader/LoaderSlice";
import { ThemedLink } from "../../common/inputs/ThemedLink";
import { setPreVerification } from "../mfa/SendOtcSlice";
import {
  dataLayerGenericInlineErrorWithArray,
  dataLayerMobileNumberPage,
  dataLayerMobileNumberPageDoThisLater,
  dataLayerMobileNumberPageDontAskMeAgain,
} from "../../tracking/tracking";
import {
  sanitiseMobileNumber,
  numericalKeyOnlyWithSpaceAndDashRegex,
  validateMobileNumber,
} from "../../common/validation/Validation";
import {
  setSkipMobileEnrollment,
  skipMobileEnrollment,
} from "../skip-mobile-enrollment/SkipMobileEnrollmentSlice";
import { useWithNav } from "../../utils/withNav";

interface MobileVerificationFormInputs {
  mobile: string;
}

function MobileNumberPage() {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<MobileVerificationFormInputs>();
  const withNav = useWithNav();
  const mobileWithNav = useWithNav<MobileInput>();

  const { dontAskAgain } = useSelector(
    (state: RootState) => state.skipMobileEnrollment
  );

  const { featureToggleState } = useSelector(
    (state: RootState) => state.appConfig
  );

  const mfaMandatoryFeatureToggle =
    featureToggleState.mfaMandatoryFeatureToggle;

  const hasError = (): boolean => {
    return !!errors?.mobile?.message || !!mobileNumberErrorMessage;
  };

  const getErrorMessage = (): string => {
    if (errors?.mobile?.message) {
      return errors.mobile.message;
    } else if (mobileNumberErrorMessage) {
      return mobileNumberErrorMessage;
    }
    return "";
  };

  let { mobileNumberErrorMessage } = useSelector(
    (state: RootState) => state.mobileNumber
  );

  let { mobile } = useSelector((state: RootState) => state.mobileNumber);
  const { isB2BUser, isB2BUserSigningUp } = useSelector(
    (state: RootState) => state.profileType
  );

  useEffect(() => {
    dispatch(setShowLoader(false));
    dispatch(setPreVerification());
  }, [dispatch]);

  const clientName = useSelector(
    (state: RootState) => state.channel.clientName
  );

  useEffect(() => {
    if (clientName) {
      dataLayerMobileNumberPage(clientName);
    }
  }, [clientName]);

  useEffect(() => {
    if (hasError()) {
      let errorMessage: string[] = [getErrorMessage()];
      dataLayerGenericInlineErrorWithArray(errorMessage);
    }
  }, [hasError]);

  const onSubmit = (formData: MobileVerificationFormInputs) => {
    if (hasError()) return;
    const mobileSanitised = sanitiseMobileNumber(formData.mobile);
    dispatch(
      saveMobileNumber(mobileWithNav({ mobileNumber: mobileSanitised }))
    );
  };

  const mobileInputField = (
    <MobileNumberInputField
      id="mobile"
      data-testid="mobile"
      type="tel"
      defaultValue={mobile}
      onKeyPress={(event) => {
        if (!numericalKeyOnlyWithSpaceAndDashRegex.test(event.key)) {
          event.preventDefault();
        }
      }}
      hasError={hasError()}
      aria-describedby="mobile-error help-text"
      aria-invalid={hasError()}
      aria-required="true"
      {...register("mobile", {
        validate: validateMobileNumber,
        onChange: (e) => {
          if (mobileNumberErrorMessage) {
            dispatch(clearMobileVerificationErrorMessage());
          }
        },
      })}
    />
  );

  return (
    <MobileDiv>
      <form
        id="mobile-verification-form"
        onSubmit={handleSubmit(onSubmit)}
        data-testid="mobile-verification-form"
        noValidate
      >
        <Header>
          <UnlockIcon />
          Secure your account
        </Header>

        <Instructions>
          To help keep your details safe, we’ll send a one-time code to your
          mobile to make sure you are you.
        </Instructions>
        <MobileNumberDescription>
          Please confirm your mobile number is correct.
        </MobileNumberDescription>

        <StyledInputLabel htmlFor="mobile">Mobile number</StyledInputLabel>

        {mobileInputField}

        {hasError() && (
          <StyledErrorText id="mobile-error" data-testid="mobile-error">
            {getErrorMessage()}
          </StyledErrorText>
        )}

        <StyledCenteredDiv>
          <StyledButton type="submit">Continue</StyledButton>
        </StyledCenteredDiv>

        {(!mfaMandatoryFeatureToggle || isB2BUser) && isB2BUserSigningUp && (
          <StyledCenteredDiv>
            <StyledLink
              data-testid="do-this-later"
              primary={false}
              href=""
              onClick={(e) => {
                e.preventDefault();
                dispatch(setSkipMobileEnrollment(dontAskAgain));
                dispatch(skipMobileEnrollment(withNav()));
                dataLayerMobileNumberPageDoThisLater();
              }}
            >
              Do this later
            </StyledLink>{" "}
          </StyledCenteredDiv>
        )}

        {(!mfaMandatoryFeatureToggle || isB2BUser) && !isB2BUserSigningUp && (
          <StyledCenteredDiv>
            <StyledLink
              data-testid="dont-ask-again"
              primary={false}
              href=""
              onClick={(e) => {
                e.preventDefault();

                dispatch(setSkipMobileEnrollment(!dontAskAgain));
                dispatch(skipMobileEnrollment(withNav()));
                dataLayerMobileNumberPageDontAskMeAgain();
              }}
            >
              Don’t ask me again
            </StyledLink>{" "}
          </StyledCenteredDiv>
        )}
      </form>
    </MobileDiv>
  );
}

export default MobileNumberPage;

const MobileDiv = styled.div`
  padding-bottom: 35px;
`;

const Header = styled(Heading1New)`
  margin-bottom: 12px;
`;

const UnlockIcon = styled(SecureLoginIconNew)`
  margin-right: 8px;
`;

const Instructions = styled.p`
  margin-top: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const MobileNumberDescription = styled(Instructions)`
  margin-bottom: 24px;
`;

const StyledInputLabel = styled(InputLabel)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
`;

const MobileNumberInputField = styled(InputFieldNew)`
  width: 90%;
`;

const StyledErrorText = styled(ErrorText)`
  margin-bottom: 20px;
`;

const StyledCenteredDiv = styled.div`
  text-align: center;
`;

const StyledButton = styled(ThemedButtonNew)`
  width: 100%;
  margin-top: 24px;
`;

const StyledLink = styled(ThemedLink)`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-top: 36px;
  color: #212121;
`;
