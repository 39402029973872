import React, { useEffect } from "react";
import { RootState } from "../../store/Store";
import { useDispatch, useSelector } from "react-redux";
import { redirectToUrl } from "../../common/utils/navigation";
import {
  Description,
  Header,
  LinkContainer,
  RelinkButton,
} from "./RelinkFlybuysPageStyles";
import {
  dataLayerFlybuysLinkPageNoThanks,
  dataLayerFlybuysRelinkPage,
} from "../../tracking/tracking";
import {
  FlybuysPrivacyUrl,
  NoThanksLink,
  SmallerDescription,
  StyledLink,
} from "./LinkFlybuysPageStyles";
import { flybuysPreferencesAction } from "./FlybuysAction";

function RelinkFlybuysPage() {
  const { linkFlybuysUrl } = useSelector(
    (state: RootState) => state.linkFlybuys
  );

  const dispatch = useDispatch();

  const clientName = useSelector(
    (state: RootState) => state.channel.clientName
  );

  useEffect(() => {
    if (clientName) {
      dataLayerFlybuysRelinkPage(clientName);
    }
  }, [clientName]);

  return (
    <>
      <Header>Re-link your Flybuys account</Header>
      <Description>
        We've had to make some security enhancements behind the scenes that
        require a quick re-link.
      </Description>
      <Description>
        To ensure you're getting the best online shopping experience we have to
        offer, please take a few moments to re-link your Flybuys account.
      </Description>

      <SmallerDescription>
        Once linked, you will also be able to collect Flybuys points on in-store
        purchases at Coles Supermarkets by scanning your Coles barcode.
      </SmallerDescription>
      <SmallerDescription>
        By scanning your Coles barcode, Flybuys will also share your email
        address and personal information with Coles in accordance with your
        email share settings and the{" "}
        <StyledLink href={FlybuysPrivacyUrl} target="_blank">
          Flybuys privacy policy
        </StyledLink>
        .
      </SmallerDescription>

      <RelinkButton
        isFullWidth={true}
        onClick={() => {
          redirectToUrl(linkFlybuysUrl);
        }}
      >
        Re-link my Flybuys
      </RelinkButton>
      <LinkContainer>
        <NoThanksLink
          data-testid="no-thanks-link"
          onClick={(e) => {
            e.preventDefault();

            dispatch(flybuysPreferencesAction({ skipFlybuys: true }));

            dataLayerFlybuysLinkPageNoThanks();
          }}
        >
          Don’t ask me again
        </NoThanksLink>
      </LinkContainer>
    </>
  );
}

export default RelinkFlybuysPage;
