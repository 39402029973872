import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AccountLinkagesState {
  clientName: string;
}

const initialState: AccountLinkagesState = {
  clientName: "",
};

export const accountLinkagesSlice = createSlice({
  name: "accountLinkageSlice",
  initialState,
  reducers: {
    accountLinkages: (state, action: PayloadAction<string>) => {},
  },
});

export const { accountLinkages } = accountLinkagesSlice.actions;

export default accountLinkagesSlice.reducer;
