import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import {
  InputFieldNew,
  InputLabel,
  Link,
  ThemedButtonNew,
  ErrorText,
  Heading1New,
  WarningNotification,
  SecureLoginIconNew,
} from "ccp-common-ui-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { setChannel, sendOtc, unsetPreVerification } from "../mfa/SendOtcSlice";
import {
  clearOtcVerificationErrorMessage,
  SecureLoginInputs,
  verifyOtc,
} from "../mfa/OtcVerificationSlice";
import { setShowLoader } from "../loader/LoaderSlice";
import { ThemedLink } from "../../common/inputs/ThemedLink";
import { dataLayerVerifyMobilePage } from "../../tracking/tracking";
import { validateOneTimeCode } from "../../common/validation/Validation";
import { mobileNumberUrl } from "../../common/constants";
import { useWithNav } from "../../utils/withNav";
import { useHistory } from "react-router-dom";

export interface MfaFormInputs {
  oneTimeCode: string;
}

function VerifyMobileNumberPage() {
  const dispatch = useDispatch();
  const secureLoginWithNav = useWithNav<SecureLoginInputs>();
  const withNav = useWithNav();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<MfaFormInputs>();
  const { push } = useHistory();

  let { otcVerificationErrorMessage } = useSelector(
    (state: RootState) => state.otcVerification
  );

  const { sentTo, maxRetryReached } = useSelector(
    (state: RootState) => state.sendOtc
  );

  const [
    isMaxRetriesNotificationOpen,
    setIsMaxRetriesNotificationOpen,
  ] = useState(maxRetryReached);

  useEffect(() => {
    maxRetryReached
      ? setIsMaxRetriesNotificationOpen(true)
      : setIsMaxRetriesNotificationOpen(false);
  }, [maxRetryReached]);

  useEffect(() => {
    dispatch(setChannel("sms"));
    dispatch(setShowLoader(false));
    dispatch(unsetPreVerification());
  }, [dispatch]);

  const onSubmit = (formData: MfaFormInputs) => {
    dispatch(verifyOtc(secureLoginWithNav(formData)));
  };

  const onSendAgain = () => {
    dispatch(clearOtcVerificationErrorMessage());
    clearErrorMessage();
    dispatch(sendOtc(withNav()));
  };

  const hasError = (): boolean => {
    return !!errors?.oneTimeCode?.message || !!otcVerificationErrorMessage;
  };

  const getErrorMessage = (): string => {
    if (errors?.oneTimeCode?.message) {
      return errors.oneTimeCode.message;
    } else if (otcVerificationErrorMessage) {
      return otcVerificationErrorMessage;
    }
    return "";
  };

  const clearErrorMessage = () => {
    if (errors?.oneTimeCode) {
      errors.oneTimeCode.message = "";
    }
    if (otcVerificationErrorMessage) {
      otcVerificationErrorMessage = "";
    }
  };

  const otcFieldRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (otcFieldRef.current) {
      otcFieldRef.current.focus();
    }
  }, [hasError]);

  const clientName = useSelector(
    (state: RootState) => state.channel.clientName
  );

  useEffect(() => {
    if (clientName) {
      dataLayerVerifyMobilePage(clientName);
    }
  }, [clientName]);
  const registerOptions = register("oneTimeCode", {
    validate: validateOneTimeCode,
    onChange: () => {
      if (otcVerificationErrorMessage) {
        dispatch(clearOtcVerificationErrorMessage());
      }
    },
  });
  const otcInputField = (
    <InputFieldNew
      id="oneTimeCode"
      data-testid="oneTimeCode"
      type="tel"
      autoComplete="off"
      {...registerOptions}
      ref={(e) => {
        otcFieldRef.current = e;
        registerOptions.ref(e);
      }}
      hasError={hasError()}
      maxLength={6}
      aria-describedby="one-time-code-error"
      aria-invalid={hasError()}
      aria-required="true"
    />
  );

  return (
    <MfaDiv>
      <form
        id="verify-mobile-number-form"
        onSubmit={handleSubmit(onSubmit)}
        data-testid="verify-mobile-number-form"
        noValidate
      >
        <Header>
          {" "}
          <UnlockIconNew />
          Verify mobile number
        </Header>
        <Instructions>
          Enter the one-time code we sent to {sentTo}.
        </Instructions>
        <StyledLabel htmlFor="oneTimeCode">One-time code</StyledLabel>
        {otcInputField}
        {hasError() && (
          <StyledErrorText
            id="one-time-code-error"
            data-testid="one-time-code-error"
          >
            {getErrorMessage()}
          </StyledErrorText>
        )}
        <SendAgainLinkContainer data-testid="send-again-text">
          <Link
            id="send-again"
            data-testid="send-again"
            primary={true}
            href="/"
            onClick={(e) => {
              e.preventDefault();
              onSendAgain();
            }}
          >
            Resend code to my mobile
          </Link>{" "}
        </SendAgainLinkContainer>
        <WarningNotificationContainer>
          <WarningNotification
            data-testid="max-retry-reached-error"
            text="You've reached the maximum limit for one-time codes sent. Wait 5 minutes to request a new code."
            isOpen={isMaxRetriesNotificationOpen}
            closeHandler={() => {
              setIsMaxRetriesNotificationOpen(false);
            }}
          />
        </WarningNotificationContainer>
        <SubmitButtonContainer>
          <StyledButton type="submit">Verify and log in</StyledButton>
        </SubmitButtonContainer>
        <DivCenterContainer>
          <ThemedStyledLink
            data-testid="verify-mobile-back-link"
            primary={false}
            href=""
            onClick={(e) => {
              e.preventDefault();
              dispatch(push(mobileNumberUrl));
            }}
          >
            Back
          </ThemedStyledLink>{" "}
        </DivCenterContainer>
      </form>
    </MfaDiv>
  );
}

export default VerifyMobileNumberPage;

const Header = styled(Heading1New)`
  margin-bottom: 8px;
`;

const Instructions = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: #3d3d3d;
`;

const StyledLabel = styled(InputLabel)`
  margin-top: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3d3d3d;
`;

const SendAgainLinkContainer = styled.div`
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const SubmitButtonContainer = styled.div`
  margin: 24px 0 36px 0;
`;

const DivCenterContainer = styled.div`
  text-align: center;
`;

const ThemedStyledLink = styled(ThemedLink)`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  color: #212121;
`;

const StyledButton = styled(ThemedButtonNew)`
  width: 100%;
`;

const StyledErrorText = styled(ErrorText)`
  margin-bottom: 20px;
`;

const MfaDiv = styled.div`
  padding-bottom: 35px;
`;

const WarningNotificationContainer = styled.div`
  margin-top: 10px;
`;
const UnlockIconNew = styled(SecureLoginIconNew)`
  margin-right: 8px;
`;
