import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ChannelState {
  clientName: string;
}
const initialState: ChannelState = {
  clientName: "",
};

export const channelSlice = createSlice({
  name: "channelSlice",
  initialState,
  reducers: {
    setClientName: (state, action: PayloadAction<string>) => {
      state.clientName = action.payload;
    },
  },
});

export const { setClientName } = channelSlice.actions;

export default channelSlice.reducer;
