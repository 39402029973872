import React from "react";
import "./ThemedCheckBox.css";

export interface ThemedInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

export const ThemedCheckBox = React.forwardRef(
  (props: ThemedInputProps, ref: React.Ref<HTMLInputElement>) => {
    return (
      <div>
        <input
          {...props}
          ref={ref}
          type="checkbox"
          className="themed-checkbox"
        />
        <label className="checkbox-label" htmlFor={props.name} />
      </div>
    );
  }
);

export default ThemedCheckBox;
