import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store/Store";
import { history } from "./store/Store";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import InvalidConfigurationErrorPage from "./features/error/InvalidConfigurationErrorPage";
import { Route, Router, Switch } from "react-router-dom";
import { CCPTheme, ColesClientName } from "ccp-common-ui-components";

declare global {
  interface Window {
    Startup: any;
    appInsights: ApplicationInsights;
  }
}

const queryParams = new URLSearchParams(window.location.search);
const clientName = queryParams.get("clientName") as ColesClientName;

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <CCPTheme clientName={clientName}>
          <Switch>
            <Route exact path="/configurationError">
              <InvalidConfigurationErrorPage />
            </Route>
            <Route path="/">
              <App />
            </Route>
          </Switch>
        </CCPTheme>
      </Router>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
