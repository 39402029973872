import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WithNav } from "../../utils/withNav";

export interface UpdateSkipMobileEnrollmentResponse {
  data: UpdateSkipMobileEnrollmentData;
}

export interface UpdateSkipMobileEnrollmentData {
  token: string;
}

export interface SkipMobileEnrollmentState {
  dontAskAgain: boolean;
}

const initialState: SkipMobileEnrollmentState = {
  dontAskAgain: false,
};

export const skipMobileEnrollmentSlice = createSlice({
  name: "skipMobileVerification",
  initialState,
  reducers: {
    setSkipMobileEnrollment: (state, action: PayloadAction<boolean>) => {
      state.dontAskAgain = action.payload;
    },
    skipMobileEnrollment: (_, _action: PayloadAction<WithNav>) => {},
    secureAccountLaterPageLoaded: () => {},
    skipMobileEnrollmentSagaFailed: () => {},
  },
});

export const {
  setSkipMobileEnrollment,
  skipMobileEnrollment,
  secureAccountLaterPageLoaded,
  skipMobileEnrollmentSagaFailed,
} = skipMobileEnrollmentSlice.actions;

export default skipMobileEnrollmentSlice.reducer;
