const dataLayerPush = (payload: any) => {
  (window as any).colData = (window as any).colData || [];
  (window as any).colData.push(payload);
};

const dataLayerSecureLoginPage = (channel: string) => {
  dataLayerPush({
    event: "view_loaded",
    data: {
      page: {
        name: "CCP Secure Login Page",
        url: "/secureLogin",
      },
      customer: {
        auth_state: "authenticated",
      },
      view: {
        context: {
          view_type: "progressive profile",
          view_type_details: `${channel}`,
          view_description: "Log in securely",
        },
      },
    },
  });
};

const dataLayerPersonalizeExperiencePage = (channel: string) => {
  dataLayerPush({
    event: "view_loaded",
    data: {
      page: {
        name: "CCP Personalize Experience Page",
        url: "/personalisation",
      },
      customer: {
        auth_state: "authenticated",
      },
      view: {
        context: {
          view_type: "progressive profile",
          view_type_details: `${channel}`,
          view_description: "Personalise your experience",
        },
      },
    },
  });
};

const dataLayerMobileNumberPage = (channel: string) => {
  dataLayerPush({
    event: "view_loaded",
    data: {
      page: {
        name: "CCP Mobile Number Page",
        url: "/mobileNumber",
      },
      customer: {
        auth_state: "authenticated",
      },
      view: {
        context: {
          view_type: "progressive profile",
          view_type_details: `${channel}`,
          view_description: "Secure your account",
        },
      },
    },
  });
};

const dataLayerMobileNumberPageDoThisLater = () => {
  dataLayerPush({
    event: "interaction",
    data: {
      interaction: {
        location: "progressive profile",
        component: "secure your account",
        description: "do this later",
      },
    },
  });
};

const dataLayerMobileNumberPageDontAskMeAgain = () => {
  dataLayerPush({
    event: "interaction",
    data: {
      interaction: {
        location: "progressive profile",
        component: "secure your account",
        description: "dont ask me again",
      },
    },
  });
};

const dataLayerVerifyMobilePage = (channel: string) => {
  dataLayerPush({
    event: "view_loaded",
    data: {
      page: {
        name: "CCP Verify Mobile Page",
        url: "/verifyMobile",
      },
      customer: {
        auth_state: "authenticated",
      },
      view: {
        context: {
          view_type: "progressive profile",
          view_type_details: `${channel}`,
          view_description: "Verify mobile number",
        },
      },
    },
  });
};

const dataLayerSecureAccountLaterPage = (channel: string) => {
  dataLayerPush({
    event: "view_loaded",
    data: {
      page: {
        name: "CCP Secure Account Later Page",
        url: "/secureAccountLater",
      },
      customer: {
        auth_state: "authenticated",
      },
      view: {
        context: {
          view_type: "progressive profile",
          view_type_details: `${channel}`,
          view_description: "Are you sure you want to skip",
        },
      },
    },
  });
};

const dataLayerSecureLoginErrorPage = (channel: string) => {
  dataLayerPush({
    event: "view_loaded",
    data: {
      page: {
        name: "CCP Secure Login Error Page",
        url: "/secureLoginError",
      },
      customer: {
        auth_state: "unauthenticated",
      },
      view: {
        context: {
          view_type: "progressive profile",
          view_type_details: `${channel}`,
          view_description: "Secure Login Error Page",
        },
      },
    },
  });
};

const dataLayerVerifyMobileErrorPage = (channel: string) => {
  dataLayerPush({
    event: "view_loaded",
    data: {
      page: {
        name: "CCP Verify Mobile Error Page",
        url: "/verifyMobileError",
      },
      customer: {
        auth_state: "authenticated",
      },
      view: {
        context: {
          view_type: "progressive profile",
          view_type_details: `${channel}`,
          view_description: "Verify Mobile Error Page",
        },
      },
    },
  });
};

const dataLayerGenericErrorPage = (channel: string) => {
  dataLayerPush({
    event: "view_loaded",
    data: {
      page: {
        name: "CCP Generic Error Page",
        url: "/genericError",
      },
      customer: {
        auth_state: "authenticated",
      },
      view: {
        context: {
          view_type: "progressive profile",
          view_type_details: `${channel}`,
          view_description: "Generic Error Page",
        },
      },
    },
  });
};

const dataLayerBusinessAttributesPage = (channel: string) => {
  dataLayerPush({
    event: "view_loaded",
    data: {
      page: {
        name: "CCP Business Attributes Page",
        url: "/businessattributes",
      },
      customer: {
        auth_state: "authenticated",
      },
      view: {
        context: {
          view_type: "progressive profile",
          view_type_details: `${channel}`,
          view_description: "Business Attributes Page",
        },
      },
    },
  });
};

const dataLayerFlybuysLinkPage = (channel: string) => {
  dataLayerPush({
    event: "view_loaded",
    data: {
      page: {
        name: "CCP Flybuys Link Page",
        url: "/linkFlybuys",
      },
      customer: {
        auth_state: "authenticated",
      },
      view: {
        context: {
          view_type: "progressive profile",
          view_type_details: `${channel}`,
          view_description: "Flybuys Link Page",
        },
      },
    },
  });
};

const dataLayerFlybuysLinkPageNoThanks = () => {
  dataLayerPush({
    event: "interaction",
    data: {
      interaction: {
        location: "progressive profile",
        component: "link your flybuys",
        description: "no thanks",
      },
      customer: {
        auth_state: "authenticated",
      },
    },
  });
};

const dataLayerFlybuysRelinkPage = (channel: string) => {
  dataLayerPush({
    event: "view_loaded",
    data: {
      page: {
        name: "CCP Flybuys Relink Page",
        url: "/relinkFlybuys",
      },
      customer: {
        auth_state: "authenticated",
      },
      view: {
        context: {
          view_type: "progressive profile",
          view_type_details: `${channel}`,
          view_description: "Flybuys Relink Page",
        },
      },
    },
  });
};

const dataLayerFlybuysRelinkPageNoThanks = () => {
  dataLayerPush({
    event: "interaction",
    data: {
      interaction: {
        location: "progressive profile",
        component: "link your flybuys",
        description: "Register a Flybuys account",
      },
    },
  });
};

const dataLayerFlybuysVerifyCallbackPage = (channel: string) => {
  dataLayerPush({
    event: "view_loaded",
    data: {
      page: {
        name: "CCP Flybuys Verify Callback Page",
        url: "/callback/flybuys",
      },
      customer: {
        auth_state: "authenticated",
      },
      view: {
        context: {
          view_type: "progressive profile",
          view_type_details: `${channel}`,
          view_description: "Flybuys Verify Callback Page",
        },
      },
    },
  });
};

const dataLayerFlybuysSkipPromptPage = (channel: string) => {
  dataLayerPush({
    event: "view_loaded",
    data: {
      page: {
        name: "CCP Flybuys Skip Prompt Page",
        url: "/skipFlybuysPrompt",
      },
      customer: {
        auth_state: "authenticated",
      },
      view: {
        context: {
          view_type: "progressive profile",
          view_type_details: `${channel}`,
          view_description: "Flybuys Skip Prompt Page",
        },
      },
    },
  });
};

const dataLayerFlybuysLinkedPage = (channel: string) => {
  dataLayerPush({
    event: "view_loaded",
    data: {
      page: {
        name: "CCP Flybuys Linked Success Page",
        url: "/success",
      },
      customer: {
        auth_state: "authenticated",
      },
      view: {
        context: {
          view_type: "progressive profile",
          view_type_details: `${channel}`,
          view_description: "Flybuys Linked Page",
        },
      },
    },
  });
};

const dataLayerFlybuysLinkingFailedPage = (channel: string) => {
  dataLayerPush({
    event: "view_loaded",
    data: {
      page: {
        name: "CCP Flybuys Linking Failed Page",
        url: "/error",
      },
      customer: {
        auth_state: "authenticated",
      },
      view: {
        context: {
          view_type: "progressive profile",
          view_type_details: `${channel}`,
          view_description: "Flybuys Linking Failed Page",
        },
      },
    },
  });
};

const dataLayerGenericInlineErrorWithArray = (errorDescArray: string[]) => {
  const josnObjectErrorDescArray = errorDescArray.map((errDescStr) => ({
    description: errDescStr,
  }));

  dataLayerPush({
    event: "error",
    data: {
      errors: josnObjectErrorDescArray,
    },
  });
};

export {
  dataLayerSecureLoginPage,
  dataLayerPersonalizeExperiencePage,
  dataLayerMobileNumberPage,
  dataLayerMobileNumberPageDoThisLater,
  dataLayerMobileNumberPageDontAskMeAgain,
  dataLayerVerifyMobilePage,
  dataLayerSecureAccountLaterPage,
  dataLayerSecureLoginErrorPage,
  dataLayerVerifyMobileErrorPage,
  dataLayerGenericErrorPage,
  dataLayerBusinessAttributesPage,
  dataLayerFlybuysLinkPage,
  dataLayerFlybuysLinkPageNoThanks,
  dataLayerFlybuysRelinkPage,
  dataLayerFlybuysRelinkPageNoThanks,
  dataLayerFlybuysVerifyCallbackPage,
  dataLayerFlybuysSkipPromptPage,
  dataLayerFlybuysLinkedPage,
  dataLayerFlybuysLinkingFailedPage,
  dataLayerGenericInlineErrorWithArray,
};
