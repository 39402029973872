import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MigrationsState {
  clientName: string;
}

const initialState: MigrationsState = {
  clientName: "",
};

export const migrationsSlice = createSlice({
  name: "migrationsSlice",
  initialState,
  reducers: {
    migrations: (state, action: PayloadAction<string>) => {},
  },
});

export const { migrations } = migrationsSlice.actions;

export default migrationsSlice.reducer;
