import React, { useEffect } from "react";
import { Heading1New, ThemedButtonNew } from "ccp-common-ui-components";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";

import {
  secureAccountLaterPageLoaded,
  setSkipMobileEnrollment,
  skipMobileEnrollment,
} from "./SkipMobileEnrollmentSlice";
import { DontAskAgainCheckBox } from "./DontAskAgainCheckBox";
import { ThemedLink } from "../../common/inputs/ThemedLink";
import { dataLayerSecureAccountLaterPage } from "../../tracking/tracking";
import { useWithNav } from "../../utils/withNav";
import { useHistory } from "react-router-dom";

function SecureAccountLaterPage() {
  const dispatch = useDispatch();
  const { handleSubmit } = useForm();
  const withNav = useWithNav();
  const { push } = useHistory();

  const { dontAskAgain } = useSelector(
    (state: RootState) => state.skipMobileEnrollment
  );

  useEffect(() => {
    dispatch(secureAccountLaterPageLoaded());
  }, [dispatch]);

  const clientName = useSelector(
    (state: RootState) => state.channel.clientName
  );

  useEffect(() => {
    if (clientName) {
      dataLayerSecureAccountLaterPage(clientName);
    }
  }, [clientName]);

  const onSubmit = () => {
    dispatch(skipMobileEnrollment(withNav()));
  };

  return (
    <MobileDiv>
      <form
        id="secure-account-later-form"
        onSubmit={handleSubmit(onSubmit)}
        data-testid="secure-account-later-form"
        noValidate
      >
        <Header>Are you sure you want to skip?</Header>
        <DontAskAgainDescription>
          Without a mobile number we can't enable two-step authentication for
          your account.
        </DontAskAgainDescription>
        <DontAskAgainCheckBox
          id="dont-ask-again"
          data-testid="dont-ask-again"
          onClick={() => {
            dispatch(setSkipMobileEnrollment(!dontAskAgain));
          }}
        />
        <StyledButton type="submit">Skip two-step authentication</StyledButton>
        <ReturnContainer>
          <ReturnLink
            data-testid="return-back-link"
            primary={false}
            href=""
            onClick={(e) => {
              e.preventDefault();
              push("/mobileNumber");
            }}
          >
            Return and enter mobile number
          </ReturnLink>{" "}
        </ReturnContainer>
      </form>
    </MobileDiv>
  );
}

export default SecureAccountLaterPage;

const Header = styled(Heading1New)`
  display: flex;
  margin-bottom: 12px;
`;

const ReturnContainer = styled.div`
  text-align: center;
`;

const ReturnLink = styled(ThemedLink)`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  color: #212121;
  margin-top: 36px;
`;

const MobileDiv = styled.div`
  padding-bottom: 35px;
`;

const StyledButton = styled(ThemedButtonNew)`
  margin-top: 24px;
  width: 100%;
`;

const DontAskAgainDescription = styled.p`
  color: #6e6e6e;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 24px;
`;
