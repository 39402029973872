import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BusinessAttributesInputs } from "./BusinessAttributesPage";
import { WithNav } from "../../utils/withNav";

const initialState: BusinessAttributesInputs = {
  businessName: "",
  abn: "",
  industryType: "",
};

export const businessAttributesSlice = createSlice({
  name: "businessAttributesSlice",
  initialState,
  reducers: {
    postBusinessAttributes: (
      _state,
      _action: PayloadAction<WithNav<BusinessAttributesInputs>>
    ) => {},
  },
});

export const { postBusinessAttributes } = businessAttributesSlice.actions;

export default businessAttributesSlice.reducer;
