import React, { useEffect } from "react";
import styled from "styled-components";
import {
  Heading1New,
  ThemedButtonNew,
  ErrorCrossCircleNew,
} from "ccp-common-ui-components";
import { useDispatch, useSelector } from "react-redux";
import { continueRule } from "../continue/ContinueSlice";
import { RootState } from "../../store/Store";
import {
  getErrorContinueText,
  getErrorMessage,
} from "../application-error/ApplicationErrorUtils";
import {
  dataLayerGenericInlineErrorWithArray,
  dataLayerSecureLoginErrorPage,
} from "../../tracking/tracking";

const SecureLoginErrorPage = function () {
  const dispatch = useDispatch();

  const clientName = useSelector(
    (state: RootState) => state.channel.clientName
  );

  const { errorCode } = useSelector(
    (state: RootState) => state.applicationError
  );

  const errorMessage = getErrorMessage(errorCode);

  useEffect(() => {
    if (clientName) {
      dataLayerSecureLoginErrorPage(clientName);
    }
    if (errorMessage) {
      dataLayerGenericInlineErrorWithArray([errorMessage]);
    }
  }, [clientName]);

  const btnText = getErrorContinueText(errorCode);

  return (
    <MainDiv>
      <ErrorCrossCircleNew role="presentation" />
      <StyledHeading1New>We can't log you in</StyledHeading1New>
      <Message>{errorMessage}</Message>
      <StyledButton
        data-testid="back-button"
        onClick={() => {
          dispatch(continueRule());
        }}
      >
        {btnText}
      </StyledButton>
    </MainDiv>
  );
};

export default SecureLoginErrorPage;

const MainDiv = styled.div`
  margin: 40px 10px;
  text-align: center;
`;

const StyledHeading1New = styled(Heading1New)`
  margin-bottom: 8px;
`;

const Message = styled.p`
  margin-top: 0px;
  margin-bottom: 24px;
`;

const StyledButton = styled(ThemedButtonNew)`
  width: 100%;
`;
